import { camelizeKeys, decamelize } from 'humps';
import _ from 'lodash';
import isomorphicFetch from 'isomorphic-fetch';
import fetchRetry from 'fetch-retry';
import * as Sentry from '@sentry/react';
import { i18n } from '@international/mastodon-i18n';
import { fetchFlag } from '@dashboard-experience/react-flagr';
import { CHECKR_API_BASE, FLAGR_URL, ENV } from '../constants';

const fetch =
  process.env.NODE_ENV === 'test' ? global.fetch : fetchRetry(isomorphicFetch);

const DEFAULT_REQUEST_OPTIONS = {
  cors: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

const CHECKR_API_REQUEST_OPTIONS = {
  credentials: 'include',
};

const postOptions = data => ({
  method: 'POST',
  body: JSON.stringify(data),
});

const patchOptions = data => ({
  method: 'PATCH',
  body: JSON.stringify(data),
});

/**
 * @name parseErrorResponse
 * @function
 * @memberOf helper
 * @description Form a consumable error object out of an HTTP response. Somtimes, the response will
 *              come from our API code, and be a json encoding of object desired.  Sometimes, if
 *              the response come from a layer such as kong, the error may not be json-formatted,
 *              so we use the plain text response in the object returned.
 * @param { Object } error error object created by parseJSONResponse
 * @returns { Promise } a promise that resolves with the error object
 */
const parseErrorResponse = error => {
  return error.response.text().then(rawText => {
    try {
      if (isJSON(rawText)) {
        const jsonText = JSON.parse(rawText);
        if (jsonText?.params) {
          return {
            errors: i18n.getConstStr(
              'errors',
              jsonText.errors,
              jsonText.params.join(', '),
            ),
          };
        }
      }
      return JSON.parse(rawText);
    } catch (err) {
      return { errors: [rawText] };
    }
  });
};

const parseJSONResponse = response => {
  if (!response.ok) {
    const message = `Error ${response.status}: ${response.statusText}`;
    const error = new Error(message);
    error.response = response;
    error.status = response.status;

    throw error;
  }

  return response.json();
};

const genericJSONRequestAsIs = (url, options = {}) => {
  const requestOptions = _.merge({}, DEFAULT_REQUEST_OPTIONS, options);
  return fetch(url, requestOptions).then(parseJSONResponse);
};

const genericJSONRequest = (url, options = {}) => {
  return genericJSONRequestAsIs(url, options).then(camelizeKeys);
};

/**
 * @template T - promise wrapped return type
 * @param {string} path - api path
 * @param {any} options - api options
 * @returns {Promise<T>} returns promise wrapped type
 */
const apiRequest = (path, options = {}) => {
  const apiRequestOptions = _.merge({}, CHECKR_API_REQUEST_OPTIONS, options);
  return genericJSONRequest(`${CHECKR_API_BASE}/${path}`, apiRequestOptions);
};

const apiRequestAsIs = (path, options = {}) => {
  const apiRequestOptions = _.merge({}, CHECKR_API_REQUEST_OPTIONS, options);
  return genericJSONRequestAsIs(
    `${CHECKR_API_BASE}/${path}`,
    apiRequestOptions,
  );
};

const buildSearchQuery = params => {
  if (_.isEmpty(params)) {
    return '';
  }
  const sets = [];
  _.each(params, (value, key) => {
    if (_.isNull(value) || _.isUndefined(value)) {
      return;
    }
    sets.push(`${decamelize(key)}=${encodeURIComponent(value)}`);
  });
  return `?${sets.join('&')}`;
};

const formatStringsInObject = data => {
  if (_.isString(data)) {
    // Replace unicode null bytes and whitespace
    return data.trim().replace(/\0/g, '');
  }
  if (_.isArray(data)) {
    return data.map(formatStringsInObject);
  }
  if (_.isPlainObject(data)) {
    return Object.keys(data).reduce((obj, key) => {
      const value = data[key];
      // eslint-disable-next-line no-param-reassign
      obj[key] = formatStringsInObject(value);
      return obj;
    }, {});
  }
  return _.cloneDeep(data);
};

const processIntlLocations = addressArr => {
  const countries = new Set();
  (addressArr || []).forEach(address => countries.add(address.country_code));
  return Array.from(countries).map(country => ({ country }));
};

const isJSON = string => {
  try {
    return !!JSON.parse(string);
  } catch (e) {
    return false;
  }
};

const formatResponseData = response => {
  const data = response;
  if (data?.format === 'international' && data?.candidate?.dob) {
    data.candidate.dob = i18n.getLocalizedDate(data.candidate.dob);
  }
  return data;
};

async function rawHtmlRequest(path, handleTextResult, options = {}) {
  const requestOptions = _.merge({}, { mode: 'cors' }, options);

  try {
    const response = await fetch(path, requestOptions);
    if (!response.ok) throw Error(response.statusText);
    const parsedData = await response.text();
    handleTextResult(parsedData);
  } catch (e) {
    Sentry.captureException(e);
  }
}

const setFlagrResponse = ({ context, entity, data }) => {
  return {
    flag: data,
    context,
    type: entity.type || 'account',
  };
};

const fetchFlagr = async (flagKey, context = {}, entity = {}) => {
  const type = entity.type || 'account';
  const id = entity.id || 'env';
  const flag = await fetchFlag(
    flagKey,
    {
      type,
      id,
      context: {
        env: ENV,
        ...context,
      },
    },
    { url: FLAGR_URL },
  );
  return setFlagrResponse({ context, entity, data: flag });
};

export {
  CHECKR_API_REQUEST_OPTIONS,
  DEFAULT_REQUEST_OPTIONS,
  apiRequest,
  apiRequestAsIs,
  buildSearchQuery,
  genericJSONRequest,
  genericJSONRequestAsIs,
  parseErrorResponse,
  parseJSONResponse,
  postOptions,
  patchOptions,
  processIntlLocations,
  formatStringsInObject,
  isJSON,
  formatResponseData,
  rawHtmlRequest,
  fetchFlagr,
};
