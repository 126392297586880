import * as ActionTypes from '../actions';

export const initialState = {
  open: false,
  childComponent: null,
  childComponentProps: null,
};

const modal = (state = initialState, action) => {
  const { type, childComponent, childComponentProps } = action;

  switch (type) {
    case ActionTypes.OPEN_MODAL:
      return {
        ...state,
        open: true,
        childComponent,
        childComponentProps,
      };
    case ActionTypes.CLOSE_MODAL:
      return {
        ...state,
        open: false,
        childComponent: null,
        childComponentProps: null,
      };
    default:
      return state;
  }
};

export default modal;
