import * as ActionTypes from '../actions';

export const initialState = {
  formIsBusy: false,
  submitting: false,
  interruptFunc: null,
  currentPage: 1,
  totalPages: 0,
  showSaveStateNotification: false,
  hidePreviousButton: false,
  hideContinueButton: false,
};

const navigation = (state = initialState, action) => {
  const { currentPageKey, newPage, type, errors, interruptFunc } = action;

  switch (type) {
    case ActionTypes.FETCH_CONFIG_SUCCESS:
      // There is only a page count for domestic checks right now
      if (!action.configuration.schema) {
        return {
          ...state,
          totalPages: action.configuration.pages.length,
        };
      }
      return state;
    case ActionTypes.CHANGE_PAGE:
      return {
        ...state,
        currentPage: newPage,
      };
    case ActionTypes.CLEAR_INTERRUPT:
      return {
        ...state,
        interruptFunc: null,
      };
    case ActionTypes.FORM_BUSY:
      return {
        ...state,
        formIsBusy: true,
      };
    case ActionTypes.FORM_IDLE:
      return {
        ...state,
        formIsBusy: false,
      };
    case ActionTypes.SUBMIT_FORM_REQUEST:
      return {
        ...state,
        formIsBusy: true,
        submitting: true,
      };
    case ActionTypes.SUBMIT_FORM_FAILURE:
      return {
        ...state,
        formIsBusy: false,
        submitting: false,
      };
    case ActionTypes.SUBMIT_FORM_SUCCESS:
      return {
        ...state,
        formIsBusy: false,
        submitting: false,
      };
    case ActionTypes.REGISTER_INTERRUPT:
      return {
        ...state,
        interruptFunc,
      };
    case ActionTypes.SUBMIT_SAVESTATE_REQUEST:
      return {
        ...state,
        formIsBusy: true,
        submitting: true,
        showSaveStateNotification: false,
      };
    case ActionTypes.SUBMIT_SAVESTATE_SUCCESS:
      return {
        ...state,
        formIsBusy: false,
        submitting: false,
        showSaveStateNotification: true,
      };
    case ActionTypes.SUBMIT_SAVESTATE_FAILURE:
      return {
        ...state,
        formIsBusy: false,
        submitting: false,
        saveStateSubmitErrors: errors,
      };
    case ActionTypes.FETCH_SAVE_STATE_REQUEST:
      return {
        ...state,
        showSaveStateNotification: false,
      };
    case ActionTypes.FETCH_SAVE_STATE_FAILURE:
      return {
        ...state,
        showSaveStateNotification: true,
      };
    case ActionTypes.FETCH_SAVE_STATE_CANCEL:
      return {
        ...state,
        showSaveStateNotification: false,
      };
    case ActionTypes.FETCH_SAVE_STATE_SUCCESS:
      return {
        ...state,
        showSaveStateNotification: false,
      };
    case ActionTypes.HIDE_SAVE_STATE_NOTIFICATION:
      return {
        ...state,
        showSaveStateNotification: false,
      };
    case ActionTypes.HIDE_SAVE_STATE_FAILURE_NOTIFICATION:
      return {
        ...state,
        saveStateSubmitErrors: null,
      };
    case ActionTypes.IDENTITY_VERIFICATION_REDIRECT:
      return {
        ...state,
        submitting: false,
        response: action.data,
        identityVerificationRedirect: action.identityVerificationRedirect,
      };
    case ActionTypes.SET_CURRENT_PAGE_KEY:
      return {
        ...state,
        currentPageKey,
      };
    case ActionTypes.SET_PREVIOUS_BUTTON_HIDDEN:
      return {
        ...state,
        hidePreviousButton: action.payload,
      };
    case ActionTypes.SET_NEXT_BUTTON_HIDDEN:
      return {
        ...state,
        hideContinueButton: action.payload,
      };
    default:
      return state;
  }
};

export default navigation;
