const CLEAR_FORM_VALUE = 'CLEAR_FORM_VALUE';
const UPDATE_INTL_FORM_VALUE = 'UPDATE_INTL_FORM_VALUE';
const CANDIDATE_TASK_FAILURE = 'CANDIDATE_TASK_FAILURE';

const clearFormValue = key => dispatch => {
  dispatch({
    type: CLEAR_FORM_VALUE,
    key,
  });
};

const updateIntlFormValue = formValue => dispatch => {
  dispatch({
    type: UPDATE_INTL_FORM_VALUE,
    formValue,
  });
};

export {
  CLEAR_FORM_VALUE,
  clearFormValue,
  UPDATE_INTL_FORM_VALUE,
  updateIntlFormValue,
  CANDIDATE_TASK_FAILURE,
};
