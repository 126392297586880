import { get, startCase, toLower, includes, filter, reduce } from 'lodash';
import { MVR_PROPERTY_KEYS } from 'international/constants.ca';
import { RTW_PROPERTY_KEYS } from 'international/constants';
import {
  FetchRequirementsResponse,
  JsonSchema,
  JsonSchemaDefinition,
  JsonSchemaProperty,
  Source,
} from 'international/types/schema.types';

export const fieldToLabel = (field: string | undefined): string | undefined => {
  if (!field) return undefined;
  return startCase(toLower(field)).replace('Id', 'ID');
};

export const getInitialValuesForIdentityDocument = (form: any) => {
  const passport = get(form, 'passport');
  if (passport) {
    return {
      id_document_number: passport.number,
      id_document_type: 'passport',
      id_document_country: passport.country,
      id_document_front: passport.document,
    };
  }
  const scopedRequirements = get(form, 'scoped_requirements', {});
  const countries = Object.keys(scopedRequirements);
  const documentTypes = ['national_id', 'driver_license'];
  for (const country of countries) {
    for (const documentType of documentTypes) {
      const countryDocument = get(scopedRequirements, [country, documentType]);
      if (countryDocument) {
        return {
          id_document_number: countryDocument.number,
          id_document_type: documentType,
          id_document_country: countryDocument.country,
          id_document_front: countryDocument.document,
        };
      }
    }
    if (country === 'GB') {
      const gbIDV = getGBValuesForIdentityDocument(
        form,
        get(scopedRequirements, ['GB']),
      );
      if (gbIDV) return gbIDV;
    }
  }
  return {};
};

const getGBValuesForIdentityDocument = (
  form: any,
  scopedGBRequirements: any,
) => {
  // check dsProofOfIdentityDocument types for GB criminal requirements
  const [dsDocType1, dsDocType2] = [
    get(form, 'dsProofOfIdentityDocument1Type')?.toLowerCase(),
    get(form, 'dsProofOfIdentityDocument2Type')?.toLowerCase(),
  ];
  if (dsDocType1 && dsDocType2) {
    if (dsDocType1 === 'passport' || dsDocType2 === 'passport') {
      const passportCountry = get(scopedGBRequirements, 'passport_country');
      const passportNumber = get(scopedGBRequirements, 'passport_number');
      if (passportCountry && passportNumber) {
        if (dsDocType1 === 'passport' || dsDocType2 === 'passport') {
          const idDocFile = get(
            scopedGBRequirements,
            dsDocType1 === 'passport'
              ? 'ds_proof_of_identity_document_1'
              : 'ds_proof_of_identity_document_2',
          );
          return {
            id_document_number: passportNumber,
            id_document_type: 'passport',
            id_document_country: passportCountry,
            id_document_front: idDocFile,
          };
        }
      }
    }

    if (dsDocType1 === 'drivinglicence' || dsDocType2 === 'drivinglicence') {
      const dlCountry = get(scopedGBRequirements, 'issuing_country');
      const dlNumber = get(scopedGBRequirements, 'drivers_license_number');
      if (dlCountry && dlNumber) {
        if (
          dsDocType1?.toLowerCase() === 'drivinglicence' ||
          dsDocType2?.toLowerCase() === 'drivinglicence'
        ) {
          const idDocFile = get(
            scopedGBRequirements,
            dsDocType1?.toLowerCase() === 'drivinglicence'
              ? 'ds_proof_of_identity_document_1'
              : 'ds_proof_of_identity_document_2',
          );
          return {
            id_document_number: dlNumber,
            id_document_type: 'driver_license',
            id_document_country: dlCountry,
            id_document_front: idDocFile,
          };
        }
      }
    }
  }

  // check dbsProofOfIdentityDocument types for GB criminal requirements
  const [dbsDocType1, dbsDocType2] = [
    get(form, 'dbsProofOfIdentityDocument1Type')?.toLowerCase(),
    get(form, 'dbsProofOfIdentityDocument2Type')?.toLowerCase(),
  ];
  if (dbsDocType1 && dbsDocType2) {
    if (
      dbsDocType1 === 'current_valid_passport' ||
      dbsDocType2 === 'current_valid_passport'
    ) {
      const passportCountry = get(scopedGBRequirements, 'passport_country');
      const passportNumber = get(scopedGBRequirements, 'passport_number');
      if (passportCountry && passportNumber) {
        if (
          dbsDocType1 === 'current_valid_passport' ||
          dbsDocType2 === 'current_valid_passport'
        ) {
          const idDocFile = get(
            scopedGBRequirements,
            dbsDocType1 === 'current_valid_passport'
              ? 'dbs_primary_group_1_document'
              : 'dbs_primary_group_1_and_secondary_group_2_document',
          );
          return {
            id_document_number: passportNumber,
            id_document_type: 'passport',
            id_document_country: passportCountry,
            id_document_front: idDocFile,
          };
        }
      }
    }
    if (
      dbsDocType1 === 'current_driving_licence' ||
      dbsDocType2 === 'current_driving_licence'
    ) {
      const dlCountry = get(scopedGBRequirements, 'issuing_country');
      const dlNumber = get(scopedGBRequirements, 'drivers_license_number');
      if (dlCountry && dlNumber) {
        if (
          dbsDocType1 === 'current_driving_licence' ||
          dbsDocType2 === 'current_driving_licence'
        ) {
          const idDocFile = get(
            scopedGBRequirements,
            dbsDocType1 === 'current_driving_licence'
              ? 'dbs_primary_group_1_document'
              : 'dbs_primary_group_1_and_secondary_group_2_document',
          );
          return {
            id_document_number: dlNumber,
            id_document_type: 'driver_license',
            id_document_country: dlCountry,
            id_document_front: idDocFile,
          };
        }
      }
    }
  }
  return null;
};

export const isCurrentAddressDomestic = (addresses: Array<any>): boolean => {
  const currentAddress = addresses.find(x => x.current_address);
  // eslint-disable-next-line camelcase
  const isAddressDomestic = currentAddress?.country_code === 'US';
  return isAddressDomestic;
};

export const formatInternationalRequirementsSchema = (
  response: FetchRequirementsResponse,
) => {
  if (!response?.schema) return response;

  const { schema } = response;
  let resp = response;
  const screeningTypes = get(schema, 'request.screening_types', []);
  if (includes(screeningTypes, 'motor_vehicle_report')) {
    resp = { ...resp, schema: formatDataForMVRScreening(schema) };
  }

  if (includes(screeningTypes, 'right_to_work')) {
    resp = { ...resp, schema: formatDataForRTWScreening(schema) };
  }

  if (includes(screeningTypes, 'credit_report')) {
    resp = { ...resp, schema: formatDataForGCCScreening(schema) };
  }

  return resp;
};

function formatDataForMVRScreening(data: JsonSchema) {
  const schema = data;
  const intlMVRSources: Source[] = filter(schema.sources, {
    screening_type: 'motor_vehicle_report',
  });

  const scopedProperties = get(
    schema,
    'properties.scoped_requirements.properties',
    {},
  );

  const dlProps: Record<string, JsonSchemaProperty> = reduce(
    Object.values(scopedProperties),
    (filteredProps: Record<string, JsonSchemaProperty>, regionProps: any) => {
      return reduce(
        Object.keys(regionProps.properties),
        (_, key: string) => {
          if (includes(MVR_PROPERTY_KEYS, key)) {
            filteredProps[key] = regionProps.properties[key]; // eslint-disable-line no-param-reassign
            delete regionProps.properties[key]; // eslint-disable-line no-param-reassign
          }
          return filteredProps;
        },
        {},
      );
    },
    {},
  );

  const dlRequiredFields: string[] = reduce(
    Object.values(scopedProperties),
    (requiredFields: string[], regionProps: any) => {
      if (regionProps.required) {
        for (let i = regionProps.required.length - 1; i >= 0; i -= 1) {
          if (includes(MVR_PROPERTY_KEYS, regionProps?.required[i])) {
            requiredFields.push(regionProps.required[i]);
            regionProps.required.splice(i, 1);
          }
        }
      }
      return requiredFields;
    },
    [],
  );

  if (Object.keys(dlProps || {}).length > 0 && dlRequiredFields?.length > 0) {
    const dlItem: JsonSchemaDefinition = {
      $id: '',
      $ref: '',
      $schema: '',
      description: 'Driver license',
      type: 'object',
      constant: undefined,
      properties: dlProps,
      required: dlRequiredFields,
      allOf: [],
    };

    const driverLicensesStruct: JsonSchemaProperty = {
      type: 'array',
      items: dlItem,
      constant: '',
      sources: intlMVRSources,
      allOf: undefined,
    };

    schema.properties.driver_licenses = driverLicensesStruct;
    schema.required?.push('driver_licenses');
  }

  return schema;
}

function formatDataForRTWScreening(data: JsonSchema) {
  const schema = data;
  const intlRTWSources: Source[] = filter(schema.sources, {
    screening_type: 'right_to_work',
  });

  const scopedProperties = get(
    schema,
    'properties.scoped_requirements.properties',
    {},
  );

  const rtwProps: Record<string, JsonSchemaProperty> = reduce(
    Object.values(scopedProperties),
    (filteredProps: Record<string, JsonSchemaProperty>, regionProps: any) => {
      return reduce(
        Object.keys(regionProps.properties),
        (_, key: string) => {
          if (includes(RTW_PROPERTY_KEYS, key)) {
            filteredProps[key] = regionProps.properties[key]; // eslint-disable-line no-param-reassign
            delete regionProps.properties[key]; // eslint-disable-line no-param-reassign
          }
          return filteredProps;
        },
        {},
      );
    },
    {},
  );

  const rtwRequiredFields: string[] = reduce(
    Object.values(scopedProperties),
    (requiredFields: string[], regionProps: any) => {
      if (regionProps.required) {
        for (let i = regionProps.required.length - 1; i >= 0; i -= 1) {
          if (includes(RTW_PROPERTY_KEYS, regionProps?.required[i])) {
            requiredFields.push(regionProps.required[i]);
            regionProps.required.splice(i, 1);
          }
        }
      }
      return requiredFields;
    },
    [],
  );

  if (Object.keys(rtwProps || {}).length > 0 && rtwRequiredFields?.length > 0) {
    const rtwItem: JsonSchemaDefinition = {
      $id: '',
      $ref: '',
      $schema: '',
      description: 'Right To Work',
      type: 'object',
      constant: undefined,
      properties: rtwProps,
      required: rtwRequiredFields,
      allOf: [],
    };

    const rtwStruct: JsonSchemaProperty = {
      type: 'array',
      items: rtwItem,
      constant: '',
      sources: intlRTWSources,
      allOf: undefined,
    };

    schema.properties.right_to_works = rtwStruct;
    schema.required?.push('right_to_works');
  }

  return schema;
}

function formatDataForGCCScreening(data: JsonSchema) {
  const schema = data;

  const intlGCCSources: Source[] = filter(schema.sources, {
    screening_type: 'credit_report',
  });

  const scopedProperties = get(
    schema,
    'properties.scoped_requirements.properties',
    {},
  );

  const gccProps: Record<string, JsonSchemaProperty> = reduce(
    Object.values(scopedProperties),
    (filteredProps: Record<string, JsonSchemaProperty>, regionProps: any) => {
      return reduce(
        Object.keys(regionProps.properties),
        (_, key: (any)) => {
          if (regionProps.properties[key].sources.length === 1) {
            filteredProps[key] = regionProps.properties[key]; // eslint-disable-line no-param-reassign
            delete regionProps.properties[key]; // eslint-disable-line no-param-reassign
          }
          return filteredProps;
        },
        {},
      );
    },
    {},
  );

  const gccRequiredFields: string[] = reduce(
    Object.values(scopedProperties),
    (requiredFields: string[], regionProps: any) => {
      if (regionProps.required) {
        for (let i = regionProps.required.length - 1; i >= 0; i -= 1) {
          if (regionProps?.required[i] in gccProps) {
            requiredFields.push(regionProps.required[i]);
            regionProps.required.splice(i, 1);
          }
        }
      }
      return requiredFields;
    },
    [],
  );

  if (Object.keys(gccProps || {}).length > 0 && gccRequiredFields?.length > 0) {
    const gccItem: JsonSchemaDefinition = {
      $id: '',
      $ref: '',
      $schema: '',
      description: 'Credit Report',
      type: 'object',
      constant: undefined,
      properties: gccProps,
      required: gccRequiredFields,
      allOf: [],
    };

    const gccStruct: JsonSchemaProperty = {
      type: 'array',
      items: gccItem,
      constant: '',
      sources: intlGCCSources,
      allOf: undefined,
    };

    schema.properties.credit_report = gccStruct;
    schema.required?.push('credit_report');
  }

  return schema;
}

export default {
  fieldToLabel,
  getInitialValuesForIdentityDocument,
  isCurrentAddressDomestic,
  formatInternationalRequirementsSchema,
};
