import Stripe from '../lib/Stripe';

import {
  FETCH_CONFIG_SUCCESS,
  UPDATE_CREDIT_CARD,
  LOAD_STRIPE_REQUEST,
  LOAD_STRIPE_SUCCESS,
} from '../actions';

const cardStyle = {
  base: {
    lineHeight: '40px',
    fontSize: '16px',
  },
};

export const initialState = {};

const creditCard = (state = initialState, action) => {
  const { type, cardEvent } = action;
  switch (type) {
    case FETCH_CONFIG_SUCCESS: {
      const {
        configuration: {
          package: { object },
        },
      } = action;

      return {
        ...state,
        object,
      };
    }
    case UPDATE_CREDIT_CARD:
      return {
        ...state,
        ...cardEvent,
      };
    case LOAD_STRIPE_REQUEST:
      return {
        ...state,
        processing: true,
      };
    case LOAD_STRIPE_SUCCESS: {
      const { price, enablePaymentIntent } = action;
      const stripe = Stripe(state.object === 'test_package');
      let cardEl;
      let elements;
      if (enablePaymentIntent) {
        const options = {
          mode: 'payment',
          currency: 'usd',
          amount: price,
          paymentMethodTypes: ['card'],
        };
        elements = stripe.elements(options);
        cardEl = elements.create('payment', {
          fields: {
            billingDetails: {
              address: {
                country: 'never',
              },
            },
          },
        });
      } else {
        cardEl = stripe.elements().create('card', { style: cardStyle });
      }

      return {
        ...state,
        stripe,
        elements,
        cardEl,
        processing: false,
      };
    }
    default:
      return state;
  }
};

export default creditCard;
