import * as ActionTypes from '../actions';

export const initialState = {
  hasInternationalAddresses: null,
  showCurrentAddressCheckbox: false,
  addressHistoryRequirementSatisfied: false,
};

const internationalAddressHistory = (state = initialState, action) => {
  const {
    hasInternationalAddresses,
    showCurrentAddressCheckbox,
    addressHistoryRequirementSatisfied,
    type,
  } = action;

  switch (type) {
    case ActionTypes.SET_HAS_INTERNATIONAL_ADDRESSES:
      return {
        ...state,
        hasInternationalAddresses,
      };
    case ActionTypes.SET_SHOW_CURRENT_ADDRESS_CHECKBOX:
      return {
        ...state,
        showCurrentAddressCheckbox,
      };
    case ActionTypes.SET_ADDRESS_HISTORY_REQUIREMENT_SATISFIED:
      return {
        ...state,
        addressHistoryRequirementSatisfied,
      };
    default:
      return state;
  }
};

export default internationalAddressHistory;
