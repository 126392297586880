export const validateFormat = (value, format, error) => {
  if (!value || value === '') {
    return;
  }
  if (!format.test(value)) {
    // eslint-disable-next-line consistent-return
    return error;
  }
};

export const validateAgainstFormat = (value, format, error) => {
  if (!value || value === '') {
    return;
  }
  if (format.test(value)) {
    // eslint-disable-next-line consistent-return
    return error;
  }
};
