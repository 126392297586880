import { generateAccessTokenAndDataRequestId } from '../lib/measureOne';

const generateCandidateAndDataRequest = () => async (dispatch, getState) => {
  try {
    const {
      // eslint-disable-next-line
      measureOneCandidateID,
      measureOneDataRequestID,
      accessToken,
    } = await generateAccessTokenAndDataRequestId(getState());

    return [false, accessToken, measureOneDataRequestID];
  } catch (err) {
    // error handling
    return [true, null, null];
  }
};

const ADD_MEASURE_ONE_DATA = 'ADD_MEASURE_ONE_DATA';
const STORE_BUFFER_MEASURE_ONE_DATA = 'STORE_BUFFER_MEASURE_ONE_DATA';

const addMeasureOneData = values => dispatch => {
  dispatch({ type: ADD_MEASURE_ONE_DATA, formValue: values });
};

const storeBufferMeasureOneData = values => dispatch => {
  dispatch({ type: STORE_BUFFER_MEASURE_ONE_DATA, formValue: values });
};

export {
  generateCandidateAndDataRequest,
  storeBufferMeasureOneData,
  ADD_MEASURE_ONE_DATA,
  STORE_BUFFER_MEASURE_ONE_DATA,
  addMeasureOneData,
};
