import ParseUrl from 'url-parse';

const validUrls = [
  'checkr.com',
  'doordash.com',
  'checkrhq-staging.net', // refactor validRedirect to support subdomains (modohr-idv.checkrhq-staging.net)
];
function validRedirect(redirectUrl) {
  if (!redirectUrl) {
    return true;
  }

  const parsedUrl = ParseUrl(redirectUrl);
  if (parsedUrl.protocol !== 'https:') {
    return false;
  }

  return validUrls.some(url => {
    const splitUrl = parsedUrl.host.split('.');
    return splitUrl.splice(-2, 2).join('.') === url;
  });
}

export { validRedirect as default };
