import _ from 'lodash';

const getScreeningSetting = (state, name, screeningType) => {
  const { screeningSettings } = state.configuration.configuration.package;
  return screeningSettings.find(
    setting => setting.name === name && setting.screeningType === screeningType,
  )?.value;
};

const getTestReasonSetting = state => {
  return getScreeningSetting(state, 'test_reason', 'drug_screening');
};

const isParsPackagePurpose = state => {
  return (
    getTestReasonSetting(state) === 'POST_ACCIDENT' ||
    getTestReasonSetting(state) === 'REASONABLE_SUSPICION'
  );
};

const getParsPackagePurpose = state => {
  switch (getTestReasonSetting(state)) {
    case 'POST_ACCIDENT':
      return 'post-accident ';
    case 'REASONABLE_SUSPICION':
      return 'reasonable suspicion ';
    default:
      return '';
  }
};

const getParsPackageModality = state => {
  const { enabledExams } = state.configuration.configuration.package;
  const services = _.flatten(
    enabledExams.map(exam => {
      return exam.services;
    }),
  );
  const examSlugs = services
    .map(service => {
      return service.name;
    })
    .toString();
  if (examSlugs.includes('NDOT')) {
    // Handles DS_BREATH_ALCOHOL_NDOT, which is non DOT
    return 'Non DOT';
  }
  if (examSlugs.includes('MIRROR')) {
    // DS_DOT_MIRROR has DOT in the name, but is not DOT
    return 'Non DOT';
  }
  if (examSlugs.includes('DOT')) {
    // Any exam with DOT that does not fall into the above is DOT
    return 'DOT';
  }
  // Every other exam is non DOT
  return 'Non DOT';
};

export {
  isParsPackagePurpose,
  getParsPackagePurpose,
  getTestReasonSetting,
  getParsPackageModality,
};
