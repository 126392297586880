/* eslint-disable camelcase */
import de from './de.json';
import en from './en.json';
import en_XL from './en_XL.json';
import es from './es.json';
import es_MX from './es_MX.json';
import fr from './fr.json';
import fr_CA from './fr_CA.json';

export default {
  de,
  en,
  en_XL,
  es,
  es_MX,
  fr,
  fr_CA,
};
