import { VALID_COUNTY } from './messages';

// eslint-disable-next-line consistent-return
const validCounty = (counties = []) => value => {
  // eslint-disable-next-line no-param-reassign
  counties = counties.map(county => county.toUpperCase());
  if (!counties.includes(value.toUpperCase())) {
    return VALID_COUNTY;
  }
};

export { validCounty as default };
