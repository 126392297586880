import * as ActionTypes from '../../actions/employment/employmentActionTypes';

export const initialState = {
  argyleAccountEmployments: [],
  argyleConnectedAccountIds: [],
  argyleCurrentState: 'uninitialized', // one of ['uninitialized', 'link-init', 'linking', 'link-error', 'polling', 'polling-timeout', 'reviewing']
};

const employmentScreening = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.ADD_ARGYLE_CONNECTED_ACCOUNT_ID:
      return {
        ...state,
        argyleConnectedAccountIds: Array.from(
          new Set([
            ...state.argyleConnectedAccountIds,
            payload.newArgyleAccountId,
          ]),
        ),
      };

    case ActionTypes.ADD_ARGYLE_ACCOUNT_EMPLOYMENTS: {
      const alreadyExists = state.argyleAccountEmployments.some(
        accountEmployments =>
          accountEmployments.argyleAccountId ===
          payload.newArgyleAccountEmployments.argyleAccountId,
      );
      return alreadyExists
        ? state
        : {
            ...state,
            argyleAccountEmployments: [
              ...state.argyleAccountEmployments,
              payload.newArgyleAccountEmployments,
            ],
          };
    }

    case ActionTypes.ARGYLE_LINK_CLOSED: {
      // If no Argyle account connected.
      if (state.argyleConnectedAccountIds.length === 0) {
        return {
          ...state,
          argyleCurrentState: 'uninitialized',
        };
      }

      // If some but not all Argyle accounts are synced.
      const accountIdsToPoll = state.argyleConnectedAccountIds.filter(
        id =>
          !state.argyleAccountEmployments.some(e => e.argyleAccountId === id),
      );
      if (accountIdsToPoll.length > 0) {
        return {
          ...state,
          argyleCurrentState: 'polling',
        };
      }

      // If all Argyle accounts are synced.
      return {
        ...state,
        argyleCurrentState: 'reviewing',
      };
    }

    case ActionTypes.RESET_ARGYLE_DATA:
      return {
        ...state,
        argyleAccountEmployments: [],
        argyleConnectedAccountIds: [],
        argyleCurrentState: 'uninitialized',
      };

    case ActionTypes.SET_ARGYLE_STATE:
      return {
        ...state,
        argyleCurrentState: payload.argyleCurrentState,
      };

    default:
      return state;
  }
};

export default employmentScreening;
