import {
  API_STATUS_REQUEST,
  API_STATUS_SUCCESS,
  API_STATUS_FAILURE,
} from '../actions/apiStatusBanner';

export const initialState = {
  apiStatusRequest: {},
  incidents: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case API_STATUS_REQUEST:
      return {
        ...state,
      };
    case API_STATUS_FAILURE:
      return {
        ...state,
        incidents: undefined,
        error: action.errors,
      };
    case API_STATUS_SUCCESS:
      return {
        ...state,
        incidents: action.incidents,
      };
    default:
      return state;
  }
};
