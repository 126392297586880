/* eslint-disable no-param-reassign */
import * as Sentry from '@sentry/react';
import { RewriteFrames } from '@sentry/integrations';
import { normalizeToSize } from '@sentry/utils';
import { cloneDeep, isObject } from 'lodash';
import traverse from 'traverse';

import { SENTRY_DSN, SENTRY_ENV, SENTRY_RELEASE } from './constants';

// 175kB, as 200kB is max payload size for sentry, sounds reasonable
const MAX_SIZE = 175 * 1024;

const keysWhitelist = [];

const ignoreErrors = [
  '[object XMLHttpRequestProgressEvent]',
  'SyntaxError: Invalid character',
  "NetworkError: Failed to execute 'send' on 'XMLHttpRequest': Failed to load 'https://privacyportal.onetrust.com/request/v1/consentreceipts'",
  "TypeError: undefined is not an object (evaluating 'window.webkit.messageHandlers.selectedDebugHandler.postMessage')",
  'FS-Cannot complete file',
  "Can't find variable: grecaptcha",
  "undefined is not an object (evaluating 'ceCurrentVideo.currentTime')",
  'Submit Validation Failed',
  "Cannot read properties of null (reading 'innerText')",
  "grecaptcha.render is not a function. (In 'grecaptcha.render(e,n)', 'grecaptcha.render' is undefined)",
  'grecaptcha.render is not a function',
  'grecaptcha is not defined',
  "null is not an object (evaluating 'document.querySelector('.alert.alert-warning').innerText')",
];

const paths = [
  /https?:\/\/apply\.checkr\.com/,
  /https?:\/\/apply\.checkrhq-staging\.net/,
  /https?:\/\/(mr-\d+-)?apply\.checkr-sandbox\.com/,
  /https?:\/\/apply\.checkr\.localhost/,
];

// team tags
const tags = {
  team: 'candidate_product',
};

/**
 * @name sanitizeState
 * @function
 * @memberOf sentry
 * @description Sanitize state based on sanitizeKeys
 * @param {(array|object)} state - state to sanitize
 */
const sanitizeState = state => {
  // sanitize state
  traverse(state).forEach(function(_) {
    if (!keysWhitelist.includes(this.key) && !isObject(this.node)) {
      this.update(null);
    }
  });
};

const initializeSentry = () => {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENV,
    // normalize context depth
    release: SENTRY_RELEASE,
    normalizeDepth: 8,
    ignoreErrors,
    allowUrls: [
      /checkr\.com/,
      /checkrhq-staging\.net/,
      /checkr-sandbox\.com/,
      /checkr\.localhost/,
    ],
    integrations: [
      new RewriteFrames({
        iteratee: frame => {
          const fr = frame;
          if (fr.filename) {
            for (const path of paths) {
              if (path.test(fr.filename)) {
                fr.in_app = true;
              }
            }
          }
          return fr;
        },
      }),
    ],
    // clean up redux state from sensitive information
    beforeSend(event) {
      const reduxState = event?.contexts?.['redux.state'];

      const newState = { ...cloneDeep(reduxState), i18n: null };

      // remove circular references
      // eslint-disable-next-line array-callback-return
      const transformedState = traverse(newState).map(function(_) {
        if (this.circular) this.remove();
      });

      const { form } = transformedState;

      sanitizeState(form);

      const scrubbedState = {
        ...transformedState,
        form,
      };

      const breadcrumbs = [...event?.breadcrumbs];
      if (breadcrumbs) {
        breadcrumbs.forEach(crumb => {
          const { data } = crumb;
          if (data?.payload) {
            sanitizeState(data.payload);
          }
        });
      }

      const newEvent = {
        ...event,
        breadcrumbs,
        contexts: {
          ...event?.contexts,
          'redux.state': scrubbedState,
        },
      };

      return normalizeToSize(newEvent, 12, MAX_SIZE);
    },
  });

  Object.keys(tags).forEach(tag => {
    Sentry.configureScope(scope => {
      scope.setTag(tag, tags[tag]);
    });
  });
};

export default initializeSentry;
