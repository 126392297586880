import { get } from 'lodash';
import { i18n, moment } from '@international/mastodon-i18n';
import { genericJSONRequestAsIs } from '../actions/helper';

import {
  MEASURE_ONE_API_HOSTNAME,
  MEASURE_ONE_JS_URL,
  REGION_COMPLIANCE_API_BASE,
} from '../constants';

const CREATE_MEASURE_ONE_DETAILS_PATH = '/education_verification_credentials';

async function generateAccessTokenAndDataRequestId(state) {
  const candidateDataObject = get(state, 'form.intlForm.values');
  // eslint-disable-next-line
  const hash = get(state, 'configuration.routeParams.token');

  const firstName = candidateDataObject.first_name;
  const lastName = candidateDataObject.last_name;
  const { email } = candidateDataObject;

  const url = `${REGION_COMPLIANCE_API_BASE}${CREATE_MEASURE_ONE_DETAILS_PATH}`;

  const payload = {
    education_verification_credential: {
      first_name: firstName,
      last_name: lastName,
      email,
      hash,
    },
  };

  const resp = await genericJSONRequestAsIs(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  const measureOneCandidateID = resp.individual_id;
  const measureOneDataRequestID = resp.datarequest_id;
  const accessToken = resp.session_id;

  return { measureOneCandidateID, measureOneDataRequestID, accessToken };
}

const getLocalizedLongMonthYearEducation = unixValue => {
  return i18n.getLocalizedMonthYear(moment.unix(unixValue));
};

const openMeasureOne = (accessToken, datarequestId) => {
  loadExternalScript(
    MEASURE_ONE_JS_URL,
    createMeasureOne(accessToken, datarequestId),
    () => {
      // @todo: handle error (TBD)
      // error logging example:
      // Sentry.captureException('Failed to load MeasureOne');
    },
  );
};

const createMeasureOne = (accessToken, datarequestId) => {
  const config = {
    access_key: accessToken,
    host_name: MEASURE_ONE_API_HOSTNAME,
    datarequest_id: datarequestId,
    branding: {
      styles: {
        primary_dark: '#186793',
        primary_light: '#2e9ccb',
        secondary_color: '#ffffff',
        min_height: '700px',
      },
    },
    options: {
      display_profile: false,
    },
  };

  // Take reference to widget
  const m1Widget = document.querySelector('m1-link');

  // Add configuration
  if (m1Widget) m1Widget.setAttribute('config', JSON.stringify(config));
};

const loadExternalScript = (url, onLoad, onError) => {
  if (!url) {
    return;
  }
  let script = document.querySelector(`script[src="${url}"]`);

  if (!script) {
    script = document.createElement('script');
    script.type = 'application/javascript';
    script.src = url;
    script.async = true;
    document.body.appendChild(script);
    script.addEventListener('load', onLoad);
    script.addEventListener('error', onError);
  } else {
    // onLoad();
  }
};

export {
  generateAccessTokenAndDataRequestId,
  getLocalizedLongMonthYearEducation,
};

export default openMeasureOne;
