import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import * as Sentry from '@sentry/react';

import rootReducer from '../reducers';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: action => {
    if (
      action?.meta &&
      (action.meta.field === 'ssn' ||
        action.meta.field === 'phone' ||
        action.meta.field === 'email' ||
        action.meta.field === 'firstName' ||
        action.meta.field === 'middleName' ||
        action.meta.field === 'lastname' ||
        action.meta.field === 'dob.year' ||
        action.meta.field === 'dob.month' ||
        action.meta.field === 'dob.day')
    ) {
      // We don't want to log any of this PII data
      // It can get partially logged while filling out the form
      return {
        ...action,
        payload: null,
      };
    }

    if (action.type === 'SUBMIT_FORM_SUCCESS') {
      // remove token from monolith response
      return {
        ...action,
        data: {
          ...action?.data,
          token: null,
        },
      };
    }

    return action;
  },
});

const configureStore = preloadedState =>
  createStore(
    rootReducer,
    preloadedState,
    compose(applyMiddleware(thunk), sentryReduxEnhancer),
  );

export default configureStore;
