import qs from 'query-string';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { i18n } from '@international/mastodon-i18n';
import { updateLocale } from '../actions';

import routes from '../routes';

class Root extends PureComponent {
  componentDidMount() {
    const { props } = this;
    const { lang } = qs.parse(props.location.search);

    props.updateLocale(lang || props.lang || i18n.getLocale());
  }

  render() {
    return routes;
  }
}

Root.defaultProps = {
  lang: i18n.getLocale() || i18n.defaultLocale,
};

Root.propTypes = {
  lang: PropTypes.string,
  location: PropTypes.object.isRequired,
  updateLocale: PropTypes.func.isRequired,
};

const mapStateToProps = ({ i18n: { locale } }) => ({
  lang: locale,
});

export default connect(mapStateToProps, { updateLocale })(Root);
