import { LICENSE_NUMBER, UNMATCHED_DL } from './messages';

const DL_STATES_REGEXES = {
  // We also maintain regexes in the monolith
  // in DriverLicenseNumberFormat
  AK: [/^\d{7}$/],
  AL: [/^\d{7,8}$/],
  AR: [/^\d{9}$/],
  AZ: [/^[A-Z]{1}\d{8}$/, /^\d{9}$/],
  CA: [/^[A-Z]{1}\d{7}$/],
  CO: [/^\d{9}$/],
  CT: [/^\d{9}$/],
  DC: [/^\d{7,9}$/],
  DE: [/^\d{2,7}$/],
  FL: [/^[A-Z]{1}\d{12}$/],
  GA: [/^\d{9}$/],
  HI: [/^H\d{8}$/, /^\d{9}$/],
  IA: [/(^\d{9}$)/, /(^\d{3}[A-Z]{2}\d{4}$)/],
  ID: [/^[A-Z]{2}\d{6}[A-Z]{1}$/, /^\d{9}$/],
  IL: [/^[A-Z]{1}\d{11,12}$/],
  IN: [/^\d{10}$/],
  KS: [/^[K]{1}\d{8}$/],
  KY: [/^[A-Z]{1}\d{8}$/],
  LA: [/^[0]?\d{7,8}$/],
  MA: [
    /(^SA\d{7}$)/, // new format for licenses starting 2018-11-13
    /(^S\d{8}$)/,
    /(^\d{9}$)/,
  ],
  MD: [
    /^[A-Z]{1}\d{12}$/,
    /^MD\d{11}$/, // new format
  ],
  ME: [/^\d{7}$/],
  MI: [/^[A-Z]{1}\d{12}$/],
  MN: [/^[A-Z]{1}\d{12}$/],
  MO: [/(^\d{9}$)/, /(^[A-Z]{1}\d{5,9}$)/, /(^\d{3}[A-Z]{1}\d{6}$)/],
  MS: [/^\d{9}$/],
  MT: [/(^\d{13}$)/, /(^(\d|[A-Z]){9}$)/, /(^[A-Z]{3}\d{10}$)/],
  NC: [/^[0]{0,5}\d{4,12}$/],
  ND: [/^(\d|[A-Z]){9}$/],
  NE: [/^[A-Z]{1}\d{3,8}$/],
  NH: [/(^\d{2}[A-Z]{3}\d{5}$)/, /(^(NHL|NHN)\d{8}$)/],
  NJ: [/^[A-Z]{1}\d{14}$/],
  NM: [/^\d{9}$/],
  NV: [/^\d{10}$/],
  NY: [/(^\d{9}$)/, /(^[A-Z]{1}\d{18}$)/],
  OH: [/^[A-Z]{1,2}\d{4,7}$/, /\d{8}$/],
  OK: [/(^\d{9}$)/, /(^[A-Z]{1}\d{9}$)/],
  OR: [/(^\d{5,7}$)/, /(^[A-Z]{1}\d{6}$)/],
  PA: [/^\d{8}$/],
  PR: [/(^\d{5,7}$)/, /^\d{9}$/],
  RI: [/(^\d{7,8}$)/, /(^V\d{6}$)/],
  SC: [/^\d{3,10}$/],
  SD: [/^\d{6,9}$/],
  TN: [/^\d{7,9}$/],
  TX: [/^\d{7,8}$/],
  UT: [/^\d{4,10}$/],
  VA: [/^[A-Z]{1}\d{8}$/, /^\d{9}$/],
  VT: [/(^\d{8}$)/, /(^\d{7}A$)/],
  WA: [
    /^[A-Z*]{4,8}\d{2,3}(\d|[A-Z]){2}$/,
    /^WDL([A-Z]|\d){9}$/, // introduced mid 2018; these licenses are also compliant w/ federal REAL ID
  ],
  WI: [/^[A-Z]{1}\d{13}$/],
  WV: [/^([A-Z]|\d){7}$/],
  WY: [/^\d{9}$/],
};

const dlFormat = (value, { dlState }) => {
  const regexps = DL_STATES_REGEXES[dlState];

  if (!regexps) {
    return;
  }

  let dlNumber = value.replace(/\s/g, '');
  dlNumber = dlNumber.replace(/-/g, '');

  const match = regexps.find(regexp => regexp.test(dlNumber));

  if (!match) {
    // eslint-disable-next-line consistent-return
    return LICENSE_NUMBER;
  }
};

// eslint-disable-next-line consistent-return
const dlMatch = (_, allValues) => {
  const { dlNumber, dlNumberConfirmation } = allValues;
  const valid = dlNumber === dlNumberConfirmation;

  if (!valid) {
    return UNMATCHED_DL;
  }
};

export { dlFormat, dlMatch };
