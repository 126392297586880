/* eslint-disable camelcase */
import { i18n, moment } from '@international/mastodon-i18n';
import { includes, map, filter, isEmpty } from 'lodash/fp';

import { InternationalAddressData } from '../../international/types/schema.types';

type Moment = typeof moment;

type uiDateData = {
  start: Moment;
  end: Moment;
  current: boolean;
};

const MONTHS_IN_YEAR = 12;

const parseDates = (address: InternationalAddressData): uiDateData => {
  const format = i18n.getMonthYearPattern();
  const { start_date, end_date, current_address } = address || {};
  const start = moment(start_date, format, true);
  const end = current_address ? moment() : moment(end_date, format, true);
  const current = current_address;
  return { start, end, current };
};

const processDates = (val: InternationalAddressData[]) => {
  let processDateErr: string = '';
  const dateSort = (a: Moment, b: Moment) => {
    if (a.start.isBefore(b.start)) return -1;
    if (a.start.isAfter(b.start)) return 1;
    if (a.start.isSame(b.start))
      processDateErr =
        'i18nInternational.landingPage.messages.addressCannotShareStartDate';
    return 0;
  };
  const dates = map(parseDates, val);
  dates.sort(dateSort);

  // We no longer have a current_address checkbox on the UI so this is necessary
  if (dates.length) dates[dates.length - 1]!.current = true;

  return { dates, processDateErr };
};

const processDateRange = (
  dates: uiDateData[],
): {
  range: uiDateData | null;
  dateRangeErr: {};
} =>
  dates.reduce<{
    range: uiDateData | null;
    dateRangeErr: {};
  }>(
    ({ range, dateRangeErr }, next) => {
      if (range) {
        const rngEndToUse = range.current ? moment() : range.end;
        const nextEndToUse = next.current ? moment() : next.end;
        const rngValid = range.start.isValid() && rngEndToUse.isValid();
        const nextValid = next.start.isValid() && nextEndToUse.isValid();

        if (rngValid && nextValid) {
          const format = i18n.getMonthYearPattern();
          if (rngEndToUse.isAfter(next.start)) {
            return {
              range,
              dateRangeErr: {
                message: 'landingPage.messages.addressOverlaps',
                startDate: next.start.format(format),
                endDate: nextEndToUse.format(format),
                rangeEndDate: rngEndToUse.format(format),
              },
            };
          }

          if (
            !rngEndToUse.isSame(next.start) &&
            !rngEndToUse.isSame(moment(next.start).subtract(1, 'month'))
          )
            return {
              range,
              dateRangeErr: {
                message: 'landingPage.messages.addressGapHistory',
                rangeEndDate: rngEndToUse.format(format),
                startDate: next.start.format(format),
              },
            };
        }
      }

      return {
        range: {
          start: range ? range.start : next.start,
          end: next.current ? moment() : next.end,
          current: false,
        },
        dateRangeErr,
      };
    },
    { range: null, dateRangeErr: {} },
  );

const processStartErr = (dates: uiDateData[]) =>
  dates.reduce<string>(
    (startErr, date) =>
      date?.start?.isValid() &&
      date?.end?.isValid() &&
      !date.start.isBefore(date.end)
        ? 'i18nInternational.addressField.startDateMustPrecede'
        : startErr,
    '',
  );

const processHistoryRequirement = (
  dates: uiDateData[],
  required_address_history_years: number,
) =>
  dates.reduce<{
    historyErr: object;
    currentAddresses: number;
  }>(
    ({ historyErr, currentAddresses }, date) => ({
      historyErr: date.end.isBefore(
        moment().subtract(
          required_address_history_years * MONTHS_IN_YEAR,
          'months',
        ),
        'months',
      )
        ? {
            message: 'landingPage.messages.doNotIncludeHistory',
            years: required_address_history_years,
          }
        : historyErr,
      currentAddresses: date.current ? currentAddresses + 1 : currentAddresses,
    }),
    { historyErr: {}, currentAddresses: 0 },
  );

const intlAddressHistoryStartDateValidator = (
  val: InternationalAddressData[],
  required_address_history_years: number,
): {} | undefined => {
  if (!val?.length)
    return {
      message: 'landingPage.messages.pleaseProvideAddressHistory',
      years: required_address_history_years,
    };

  const { dates, processDateErr } = processDates(val);
  if (processDateErr) return processDateErr;

  const startDateErr = processStartErr(dates);
  if (startDateErr) return startDateErr;

  const { range, dateRangeErr } = processDateRange(dates);
  if (Object.entries(dateRangeErr)?.length) return dateRangeErr;

  return range?.start.isValid() &&
    range?.end.isValid() &&
    range.end.diff(range.start, 'months') <
      required_address_history_years * MONTHS_IN_YEAR
    ? {
        message: 'landingPage.messages.pleaseProvideAddressHistory',
        years: required_address_history_years,
      }
    : undefined;
};

const intlAddressHistoryEndDateValidator = (
  val: InternationalAddressData[],
  required_address_history_years: number,
): {} | undefined => {
  if (!val?.length)
    return {
      message: 'landingPage.messages.pleaseProvideAddressHistory',
      years: required_address_history_years,
    };

  const { dates, processDateErr } = processDates(val);

  const { historyErr, currentAddresses } = processHistoryRequirement(
    dates,
    required_address_history_years,
  );
  if (!isEmpty(historyErr)) return historyErr;
  if (currentAddresses > 1)
    return 'i18nInternational.landingPage.messages.canHaveCurrentAddress';

  if (processDateErr) return undefined;
  const startDateErr = processStartErr(dates);
  if (startDateErr) return undefined;

  const { range, dateRangeErr } = processDateRange(dates);
  if (Object.entries(dateRangeErr)?.length) return dateRangeErr;
  const validRange =
    range?.start.isValid() && range?.end.isValid() && currentAddresses !== 1;
  return (
    validRange && {
      message: 'landingPage.messages.pleaseProvideAddressHistory',
      years: required_address_history_years,
    }
  );
};

const intlAddressStateValidator = (
  val: string,
  addresses: InternationalAddressData[],
): string | undefined => {
  if (!val) return undefined;
  return includes(
    'US',
    map('country_code', filter({ state: val }, addresses)),
  ) && val.length > 2
    ? i18n.getStr('i18nInternational.addressField.stateAbbreviation')
    : undefined;
};

export {
  intlAddressStateValidator,
  intlAddressHistoryStartDateValidator,
  intlAddressHistoryEndDateValidator,
  processHistoryRequirement,
};
