export const CANADA_LICENSE_NUMBER = 'validations.canadaLicenseNumber';
export const DATE_AFTER = 'validations.dateAfter';
export const DATE_FORMAT = 'validations.dateFormat';
export const DATE_IN_THE_PAST = 'validations.dateInThePast';
export const DATE_IN_THE_FUTURE = 'validations.dateInTheFuture';
export const DATE_WITHIN_FIVE_YEARS = 'validations.dateWithinFiveYears';
export const EMAIL = 'validations.email';
export const FIELD_LENGTH_MAX = 'validations.fieldLength';
export const FIELD_LENGTH_MIN = 'validations.fieldLengthMin';
export const INVALID_SELECTION = 'validations.invalidSelection';
export const LICENSE_NUMBER = 'validations.licenseNumber';
export const LAST_NAME = 'validations.lastNameForm';
export const NAME = 'validations.nameForm';
export const FULL_NAME_WORDS_NUMBER = 'validations.fullNameTwoWordsRequired';
export const NO_PO_BOX_ADDRESS = 'validations.poBoxFormat';
export const NO_US_TERRITORIES = 'validations.noUsAddressHistory';
export const REQUIRED_DOCUMENTS = 'validations.requiredDocuments';
export const REQUIRED_TWO_DOCUMENTS = 'validations.requiredDocuments.two';
export const REQUIRED = 'validations.required';
export const SIGNATURE_FORMAT = 'validations.signatureFormat';
export const UNMATCHED_DL = 'validations.unmatchedDL';
export const UNMATCHED_SSN = 'validations.unmatchedSSN';
export const VALID_CC = 'validations.validCC';
export const VALID_COUNTY = 'validations.validCounty';
export const VALID_SSN_FORMAT = 'validations.validSSNFormat';
export const VALID_SSN = 'validations.validSSN';
export const VALID_URL_FORMAT = 'validations.validUrlFormat';
export const ZIPCODE_FORMAT = 'validations.zipcodeFormatUS';
export const PHONE_NUMBER_FORMAT = 'validations.phoneNumberFormatUS';
export const NUMBERS_ONLY_FORMAT = 'validations.onlyNumbers';
export const CURRENT_SCHOOL_DISALLOWED = 'validations.currentSchoolDisallowed';
