import { change, getFormValues } from 'redux-form';
import { datadogRum } from '@datadog/browser-rum';
import {
  requestConfigurationUpdate,
  requestAppointmentConfiguration,
  requestConfiguration,
} from './configuration';
import { EXAM_SELECTIONS } from '../lib/utils/healthScreeningUtils';
import { findEnvVar } from '../lib/helpers';

const DD_RUM_CLIENT_TOKEN = findEnvVar('DD_RUM_CLIENT_TOKEN');
const DD_RUM_APPLICATION_ID = findEnvVar('DD_RUM_APPLICATION_ID');
const DD_RUM_SITE = findEnvVar('DD_RUM_SITE');

const initializeSelectedExams = () => (dispatch, getState) => {
  const state = getState();
  const { selectedExams, examWaivers } = getFormValues('applyform')(state);
  const defaultExamSelection =
    state.configuration?.configuration?.examSlugs || [];

  if (selectedExams === undefined) {
    dispatch(change('applyform', 'selectedExams', defaultExamSelection));
  }
  if (examWaivers === undefined) {
    dispatch(change('applyform', 'examWaivers', []));
  }
};

const selectExam = (slug, selectedOption) => (dispatch, getState) => {
  const state = getState();
  const { selectedExams } = getFormValues('applyform')(state);
  const { enabledExams } = state.configuration.configuration.package;

  const exams = enabledExams.map(cat => cat.services).flat();
  const exam = exams.find(e => e.name === slug);

  const swapExamSlugs = (oldSlugs, newSlug) => {
    const newExams = selectedExams.filter(s => !oldSlugs.includes(s));
    if (newSlug && !newExams.includes(newSlug)) {
      newExams.push(newSlug);
    }
    return newExams;
  };

  let updatedExams;
  switch (selectedOption) {
    case EXAM_SELECTIONS.scheduleTest:
    case EXAM_SELECTIONS.scheduleVaccine:
      updatedExams = swapExamSlugs([exam.counterpartSlug], exam.name);
      break;
    case EXAM_SELECTIONS.testForImmunity:
      updatedExams = swapExamSlugs([exam.name], exam.counterpartSlug);
      break;
    case EXAM_SELECTIONS.uploadDocumentation:
      updatedExams = swapExamSlugs([exam.name, exam.counterpartSlug]);
      break;
    default:
      throw new Error(`Unexpected exam selection: ${selectedOption}`);
  }

  dispatch(change('applyform', 'selectedExams', updatedExams));
  dispatch(change('applyform', `examWaiverUpload.${slug}`, undefined));
  dispatch(setExamWaivers(slug, []));

  const requiredExamCount = exams.filter(e => !e.waivable).length;
  const hasSelectedExams = requiredExamCount + updatedExams.length > 0;
  dispatch(requestHealthScreeningConfigUpdate(hasSelectedExams));
};

const setExamWaivers = (examSlug, documents) => (dispatch, getState) => {
  const updatedWaiver = { examSlug, documents };
  const state = getState();
  const { examWaivers } = getFormValues('applyform')(state);
  const updatedWaivers = examWaivers.filter(
    waiver => waiver.examSlug !== examSlug,
  );
  if (documents.length > 0) {
    updatedWaivers.push(updatedWaiver);
  }
  dispatch(change('applyform', 'examWaivers', updatedWaivers));
};

const requestHealthScreeningConfigUpdate = (hasSelectedExams, updatedExams) => (
  dispatch,
  getState,
) => {
  const { appointmentId } = getState().configuration.routeParams;
  const requestorMethod = appointmentId
    ? requestAppointmentConfiguration
    : requestConfiguration;
  const options = {
    params: { hasSelectedExams, updatedExams },
    requestorMethod,
  };
  requestConfigurationUpdate(options)(dispatch, getState);
};

const logLocationMetrics = (
  accountId,
  accountName,
  node,
  packageId,
  location,
) => {
  if (DD_RUM_CLIENT_TOKEN && DD_RUM_APPLICATION_ID && DD_RUM_SITE && location) {
    datadogRum.addAction('select_clinic_location', {
      accountId,
      accountName,
      node,
      packageId,
      location,
    });
  }
};

export {
  initializeSelectedExams,
  selectExam,
  setExamWaivers,
  requestHealthScreeningConfigUpdate,
  logLocationMetrics,
};
