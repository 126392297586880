import { NO_US_TERRITORIES } from './messages';
import { US_COUNTRY_TERRITORIES } from '../../constants';

// eslint-disable-next-line consistent-return
const nonUsTerritoriesValidation = value => {
  if (US_COUNTRY_TERRITORIES.includes(value)) {
    return NO_US_TERRITORIES;
  }
};

export { nonUsTerritoriesValidation as default };
