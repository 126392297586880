// eslint-disable-next-line import/no-cycle
import { requestConfigurationUpdate } from './configuration';
import { handlePreflightSubmit } from './preflight';

const FORM_BUSY = 'FORM_BUSY';
const FORM_IDLE = 'FORM_IDLE';
const CHANGE_PAGE = 'CHANGE_PAGE';
const REGISTER_INTERRUPT = 'INTERRUPT';
const CLEAR_INTERRUPT = 'CLEAR_INTERRUPT';
const HIDE_SAVE_STATE_NOTIFICATION = 'HIDE_SAVE_STATE_NOTIFICATION';
const HIDE_SAVE_STATE_FAILURE_NOTIFICATION =
  'HIDE_SAVE_STATE_FAILURE_NOTIFICATION';
const IDENTITY_VERIFICATION_REDIRECT = 'IDENTITY_VERIFICATION_REDIRECT';
const IDENTITY_DOCUMENT_DEFAULT_VALUES = 'IDENTITY_DOCUMENT_DEFAULT_VALUES';
const HIDE_SAVE_LOCALE_FAILURE_NOTIFICATION =
  'HIDE_SAVE_LOCALE_FAILURE_NOTIFICATION';
const SET_CURRENT_PAGE_KEY = 'SET_CURRENT_PAGE_KEY';
const SET_PREVIOUS_BUTTON_HIDDEN = 'SET_PREVIOUS_BUTTON_HIDDEN';
const SET_NEXT_BUTTON_HIDDEN = 'SET_NEXT_BUTTON_HIDDEN';

const changePage = (newPage, configuration) => ({
  type: CHANGE_PAGE,
  newPage,
});

const registerNavigationInterrupt = interruptFunc => dispatch => {
  dispatch(registerNavigationInterruptAction(interruptFunc));
};
const registerNavigationInterruptAction = interruptFunc => ({
  type: REGISTER_INTERRUPT,
  interruptFunc,
});

const getInterruptHandler = callback => (_, getState) => {
  return (...params) => {
    const { interruptFunc } = getState().navigation;
    if (interruptFunc && interruptFunc()) return () => {};
    return callback(...params);
  };
};

const clearNavigationInterrupt = () => dispatch => {
  dispatch(clearNavigationInterruptAction());
};
const clearNavigationInterruptAction = () => ({ type: CLEAR_INTERRUPT });

const handleNextPage = values => (dispatch, getState) => {
  const retrievedState = getState();
  const { configuration, routeParams } = retrievedState.configuration;
  const previousPage =
    configuration.pages[retrievedState.navigation.currentPage - 1];
  const previousPageName = previousPage.name;
  const nextPage = retrievedState.navigation.currentPage + 1;

  if (previousPageName === 'authenticateInvitationForm') {
    const { partialSsn } = retrievedState.configuration;
    dispatch(
      requestConfigurationUpdate({
        params: { partialSsn },
        refreshing: false,
        callback: () => dispatch(changePage(nextPage, configuration)),
      }),
    );
  } else {
    if (previousPageName === 'welcome') {
      const ssnRequired = previousPage.components.reduce(
        (component, found) =>
          found ||
          (component.component === 'ApplicantInformationForm' &&
            component.props.ssn),
        false,
      );
      if (ssnRequired) {
        dispatch(
          handlePreflightSubmit({
            ...values,
            company: routeParams.company,
          }),
        );
      }
    }
    dispatch(changePage(nextPage, configuration));
    window.scrollTo(0, 0);
  }
};

const handlePreviousPage = e => (dispatch, getState) => {
  e.preventDefault();
  const retrievedState = getState();
  const { configuration } = retrievedState.configuration;
  const previousPage = retrievedState.navigation.currentPage - 1;
  dispatch(changePage(previousPage, configuration));
  window.scrollTo(0, 0);
};

const handleIncorrectInformation = e => (dispatch, getState) => {
  e.preventDefault();
  const retrievedState = getState();
  const { configuration } = retrievedState.configuration;
  dispatch(
    requestConfigurationUpdate({
      params: { notMe: true },
      callback: () => {
        dispatch(changePage(1, configuration));
        window.scrollTo(0, 0);
      },
    }),
  );
};

const hideSaveStateNotification = e => dispatch => {
  dispatch({ type: HIDE_SAVE_STATE_NOTIFICATION });
};

const hideSaveStateFailureNotification = e => dispatch => {
  dispatch({ type: HIDE_SAVE_STATE_FAILURE_NOTIFICATION });
};

const setIdentityDocumentDefaultValues = identityDocumentDefaultValues => dispatch => {
  dispatch({
    type: IDENTITY_DOCUMENT_DEFAULT_VALUES,
    identityDocumentDefaultValues,
  });
};

const hideSaveLocaleFailureNotification = e => dispatch => {
  dispatch({ type: HIDE_SAVE_LOCALE_FAILURE_NOTIFICATION });
};

const setCurrentPageKey = currentPageKey => dispatch => {
  dispatch({
    type: SET_CURRENT_PAGE_KEY,
    currentPageKey,
  });
};

export {
  CHANGE_PAGE,
  CLEAR_INTERRUPT,
  REGISTER_INTERRUPT,
  FORM_BUSY,
  FORM_IDLE,
  HIDE_SAVE_STATE_NOTIFICATION,
  HIDE_SAVE_STATE_FAILURE_NOTIFICATION,
  IDENTITY_VERIFICATION_REDIRECT,
  IDENTITY_DOCUMENT_DEFAULT_VALUES,
  HIDE_SAVE_LOCALE_FAILURE_NOTIFICATION,
  SET_CURRENT_PAGE_KEY,
  SET_PREVIOUS_BUTTON_HIDDEN,
  SET_NEXT_BUTTON_HIDDEN,
  changePage,
  clearNavigationInterrupt,
  clearNavigationInterruptAction,
  getInterruptHandler,
  handleNextPage,
  handlePreviousPage,
  handleIncorrectInformation,
  hideSaveStateNotification,
  hideSaveStateFailureNotification,
  setIdentityDocumentDefaultValues,
  registerNavigationInterrupt,
  registerNavigationInterruptAction,
  hideSaveLocaleFailureNotification,
  setCurrentPageKey,
};
