import * as ActionTypes from '../actions';

export const initialState = {
  ready: true,
  locations: null,
  zipcode: null,
  warning: null,
  timeout: false,
  occupationalHealthOptions: {},
  oneEightHundredFlowSelected: false,
};

const healthScreening = (state = initialState, action) => {
  const {
    locations,
    zipcode,
    searchedZipcode,
    warning,
    timeout,
    type,
    showLocationsWithExtendedHoursOnly,
  } = action;

  switch (type) {
    case ActionTypes.FETCH_LOCATIONS:
      return {
        ...state,
        warning: null,
        timeout: false,
        ready: false,
      };
    case ActionTypes.FETCH_LOCATIONS_SUCCESS:
      return {
        ...state,
        ready: true,
        timeout: false,
        locations,
        selectedLocation: null,
        searchedZipcode,
      };
    case ActionTypes.UPDATE_SEARCH_ZIPCODE:
      return {
        ...state,
        zipcode,
      };
    case ActionTypes.UPDATE_SEARCH_EXTENDED_HOURS:
      return {
        ...state,
        showLocationsWithExtendedHoursOnly,
      };
    case ActionTypes.FETCH_LOCATIONS_FAILED:
      return {
        ...state,
        ready: true,
        locations: [],
        warning,
        timeout,
      };
    case ActionTypes.SUBMIT_FORM_REQUEST:
      return {
        ...state,
        ready: false,
      };
    case ActionTypes.SUBMIT_FORM_FAILURE:
      return {
        ...state,
        ready: true,
      };
    case ActionTypes.SUBMIT_FORM_SUCCESS:
      return {
        ...state,
        ready: true,
      };
    case ActionTypes.SELECT_ONE_EIGHT_HUNDRED_FLOW:
      return {
        ...state,
        oneEightHundredFlowSelected: true,
      };
    default:
      return state;
  }
};

export default healthScreening;
