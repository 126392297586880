import { INVALID_SELECTION } from './messages';

const validSelection = (value, dropdownOptionObjects) => {
  const dropdownOptionValues = dropdownOptionObjects.map(
    option => option.value,
  );
  return !dropdownOptionValues.includes(value) ? INVALID_SELECTION : null;
};

export { validSelection as default };
