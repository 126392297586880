import React from 'react';
import Loadable from 'react-loadable';
import { Switch, Route } from 'react-router-dom';

import Loading from 'components/Loading';
import CommonRoutes from './commonRoutes';

const Hello = Loadable({
  loader: () => import(/* webpackChunkName: "hello" */ '../components/Hello'),
  loading: Loading,
});

export default (
  <Switch>
    <Route exact path='/' component={Hello} />
    <CommonRoutes />
  </Switch>
);
