import { VALID_SSN, VALID_SSN_FORMAT, UNMATCHED_SSN } from './messages';

const firstSection = [0, 3];
const secondSection = [4, 6];
const thirdSection = [7, 11];

const ssnCorrectFormat = ssn => {
  return /^\d{3}-\d{2}-\d{4}$/.test(ssn);
};

const isITIN = ssn => {
  return ssn.slice(0, 1) === '9';
};

const zerosInAnySection = ssn => {
  return (
    ssn.slice(...firstSection) === '000' ||
    ssn.slice(...secondSection) === '00' ||
    ssn.slice(...thirdSection) === '0000'
  );
};

const startsWith666 = ssn => {
  return ssn.slice(...firstSection) === '666';
};

const SSN_BLOCKLIST = [
  '001-01-0101',
  '012-34-5678',
  '078-05-1120',
  '111-11-1111',
  '123-45-1234',
  '123-45-6789',
  '219-09-9999',
  '222-22-2222',
  '333-33-3333',
  '444-44-4444',
  '454-54-5454',
  '555-55-5555',
  '777-77-7777',
  '888-88-8888',
];
const inBlocklist = ssn => {
  return SSN_BLOCKLIST.includes(ssn);
};

// eslint-disable-next-line consistent-return
const ssnValid = ssn => {
  const valid =
    ssnCorrectFormat(ssn) &&
    !isITIN(ssn) &&
    !zerosInAnySection(ssn) &&
    !startsWith666(ssn) &&
    !inBlocklist(ssn);

  if (!ssnCorrectFormat(ssn)) {
    return VALID_SSN_FORMAT;
  }
  if (!valid) {
    return VALID_SSN;
  }
};

// eslint-disable-next-line consistent-return
const ssnMatch = (_, allValues) => {
  const { ssn, ssnConfirmation } = allValues;
  const valid = ssn === ssnConfirmation;
  if (!valid) {
    return UNMATCHED_SSN;
  }
};

const ssnValidations = (
  model,
  ssnConfirmation = false,
  protectedInvitationFlow,
  candidate,
) => {
  const value = ssnConfirmation ? model.ssnConfirmation : model.ssn;
  if ((protectedInvitationFlow && candidate.ssn) || model.noSsn) {
    return undefined;
  }
  const result = ssnValid(value);
  return ssnConfirmation ? result || ssnMatch(undefined, model) : result;
};

export { ssnValid, ssnMatch, ssnValidations };
