import { change, formValueSelector, submit } from 'redux-form';
import { parseErrorResponse, apiRequest, buildSearchQuery } from './helper';
// eslint-disable-next-line import/no-cycle
import { FORM_BUSY, FORM_IDLE, clearNavigationInterrupt } from './navigation';
import { closeModal } from './modal';
import {
  requestConfigurationUpdate,
  requestAppointmentConfiguration,
  requestConfiguration,
} from './configuration';

const FETCH_LOCATIONS = 'FETCH_LOCATIONS';
const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS';
const FETCH_LOCATIONS_FAILED = 'FETCH_LOCATIONS_FAILED';
const UPDATE_SEARCH_ZIPCODE = 'UPDATE_SEARCH_ZIPCODE';
const UPDATE_SEARCH_EXTENDED_HOURS = 'UPDATE_SEARCH_EXTENDED_HOURS';

const SELECT_ONE_EIGHT_HUNDRED_FLOW = 'SELECT_ONE_EIGHT_HUNDRED_FLOW';
const selector = formValueSelector('applyform');

const updateSearchZipcode = zipcode => dispatch => {
  dispatch({ type: UPDATE_SEARCH_ZIPCODE, zipcode });
};

const updateSearchExtendedHours = showLocationsWithExtendedHoursOnly => dispatch => {
  dispatch({
    type: UPDATE_SEARCH_EXTENDED_HOURS,
    showLocationsWithExtendedHoursOnly,
  });
};

const healthScreeningLocationsPath = searchQuery => {
  return `health_screenings/locations${buildSearchQuery(searchQuery)}`;
};

const getRequiredExams = state => {
  const examCategories = state.configuration.configuration.package.enabledExams;

  return examCategories
    .map(category => category.services)
    .flat()
    .filter(exam => !exam.waivable);
};

const getSelectedExams = state => {
  // All possible exams on the package
  const examCategories = state.configuration.configuration.package.enabledExams;
  // If no exams are selected, check to see if they are specified in the configuration
  const selectedExams =
    selector(state, 'selectedExams') ||
    state.configuration?.configuration?.examSlugs;
  // Otherwise, assume all exams are required
  const areAllExamsRequired = selectedExams == null;

  return examCategories
    .map(category => category.services)
    .flat()
    .filter(exam => areAllExamsRequired || selectedExams.includes(exam.name));
};

const manualLocationSelect = location => dispatch => {
  dispatch(change('applyform', 'location', location));
};

const resultContainsCurrentLocation = (state, results) => {
  const currentLocation = selector(state, 'location');
  if (!currentLocation) return false;

  return (
    results.filter(location => location.siteId === currentLocation.siteId)
      .length > 0
  );
};

const addIndexes = objs => {
  return objs.map((obj, index) => {
    return { ...{ originalIndex: index }, ...obj };
  });
};

const selectOneEightHundredFlow = () => (dispatch, getState) => {
  dispatch({ type: SELECT_ONE_EIGHT_HUNDRED_FLOW });
  const options = {
    params: { oneEightHundredFlowSelected: true },
    requestorMethod: requestConfiguration,
  };
  requestConfigurationUpdate(options)(dispatch, getState);
};

const requestLocations = () => (dispatch, getState) => {
  const state = getState();
  const examSlugs = getSelectedExams(state).map(exam => exam.name);
  const config = state.configuration.configuration;
  const searchQuery = {
    zipcode: state.healthScreening.zipcode,
    exam_slugs: examSlugs,
    account_id: config.account.id,
    package_id: config.package.id,
    test_package: config.package.object === 'test_package',
    requires_observed_collection: !!config.package.requiresObservedDrugTest,
    show_expanded_hours_only:
      state.healthScreening.showLocationsWithExtendedHoursOnly,
  };
  dispatch({ type: FETCH_LOCATIONS });
  dispatch({ type: FORM_BUSY });
  return apiRequest(healthScreeningLocationsPath(searchQuery))
    .then(json => {
      if (!resultContainsCurrentLocation(state, json)) {
        dispatch(change('applyform', 'location', null));
      }
      dispatch({
        type: FETCH_LOCATIONS_SUCCESS,
        locations: addIndexes(json),
        searchedZipcode: state.healthScreening.zipcode,
      });
      dispatch({ type: FORM_IDLE });
    })
    .catch(e => {
      parseErrorResponse(e).then(({ errors }) => {
        dispatch({ type: FORM_IDLE });
        dispatch({
          type: FETCH_LOCATIONS_FAILED,
          warning: errors.join(', '),
          timeout: e.status === 424,
        });
      });
    });
};

const handleRequireMailingAddress = ({ requireMail }) => (
  dispatch,
  getState,
) => {
  const { appointmentId } = getState().configuration.routeParams;
  const requestorMethod = appointmentId
    ? requestAppointmentConfiguration
    : requestConfiguration;
  const continueApplyFlow = () => {
    clearNavigationInterrupt()(dispatch);
    closeModal()(dispatch);
    dispatch(submit('applyform'));
  };
  const options = {
    params: { nonElectronicClinic: requireMail },
    callback: continueApplyFlow,
    requestorMethod,
  };
  requestConfigurationUpdate(options)(dispatch, getState);
};

export {
  FETCH_LOCATIONS,
  FETCH_LOCATIONS_FAILED,
  FETCH_LOCATIONS_SUCCESS,
  UPDATE_SEARCH_ZIPCODE,
  UPDATE_SEARCH_EXTENDED_HOURS,
  SELECT_ONE_EIGHT_HUNDRED_FLOW,
  handleRequireMailingAddress,
  healthScreeningLocationsPath,
  manualLocationSelect,
  addIndexes,
  resultContainsCurrentLocation,
  requestLocations,
  updateSearchZipcode,
  updateSearchExtendedHours,
  getRequiredExams,
  getSelectedExams,
  selectOneEightHundredFlow,
};
