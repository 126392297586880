import { STRIPE_URL } from '../constants';

const UPDATE_CREDIT_CARD = 'UPDATE_CREDIT_CARD';
const LOAD_STRIPE_REQUEST = 'LOAD_STRIPE_REQUEST';
const LOAD_STRIPE_SUCCESS = 'LOAD_STRIPE_SUCCESS';

const updateCreditCard = cardEvent => ({
  type: UPDATE_CREDIT_CARD,
  cardEvent,
});

const conditionalCardProcessing = ({
  stripe,
  cardEl,
  elements,
  elementType,
}) => {
  if (!cardEl) {
    return Promise.resolve({});
  }
  if (elementType === 'payment') {
    return elements.submit().then(() =>
      stripe.createConfirmationToken({
        elements,
        params: {
          payment_method_data: {
            billing_details: {
              address: {
                country: 'US',
              },
            },
          },
        },
      }),
    );
  }
  return stripe.createToken(cardEl);
};

const loadStripe = (price, enablePaymentIntent) => dispatch => {
  dispatch({ type: LOAD_STRIPE_REQUEST });
  const script = document.createElement('script');
  script.src = STRIPE_URL;

  script.addEventListener(
    'load',
    () => dispatch({ type: LOAD_STRIPE_SUCCESS, price, enablePaymentIntent }),
    {
      once: true,
    },
  );

  document.head.appendChild(script);
};

export {
  UPDATE_CREDIT_CARD,
  LOAD_STRIPE_REQUEST,
  LOAD_STRIPE_SUCCESS,
  conditionalCardProcessing,
  updateCreditCard,
  loadStripe,
};
