import * as ActionTypes from '../actions';
import { validRedirect } from '../lib/validations';

export const initialState = {
  fetching: true,
  configuration: {
    layout: {
      supportedLanguages: { en: 'English', es: 'Español' },
    },
  },
  refreshing: false,
  partialSsn: null,
};

const configuration = (state = initialState, action) => {
  const {
    // eslint-disable-next-line no-shadow
    configuration,
    error,
    routeParams,
    refreshing,
    type,
    partialSsn,
    data,
  } = action;

  const nextState = routeParams
    ? {
        ...state,
        validRedirect: validRedirect(routeParams.redirect),
        manualRedirect: routeParams.redirect,
      }
    : state;

  switch (type) {
    case ActionTypes.FETCH_CONFIG_REQUEST:
      return {
        ...nextState,
        routeParams,
        fetching: true,
        refreshing,
      };
    case ActionTypes.FETCH_CONFIG_FAILURE:
      return {
        ...nextState,
        configuration: initialState.configuration,
        fetching: false,
        error,
      };
    case ActionTypes.FETCH_CONFIG_SUCCESS:
      return {
        ...nextState,
        configuration,
        fetching: false,
        receivedAt: Date.now(),
        refreshing: false,
      };
    case ActionTypes.SET_PARTIAL_SSN:
      return {
        ...nextState,
        partialSsn,
      };
    case ActionTypes.FETCH_INTL_REQUIREMENTS_CONFIG:
      return {
        ...state,
        fetching: true,
      };
    case ActionTypes.FETCH_INTL_REQUIREMENTS_CONFIG_FAILURE:
      return {
        ...state,
        fetching: false,
        submitting: false,
      };
    case ActionTypes.FETCH_SAVE_STATE_REQUEST:
      return {
        ...state,
        fetchingSaveState: true,
      };
    case ActionTypes.FETCH_SAVE_STATE_SUCCESS:
      return {
        ...state,
        fetchingSaveState: false,
        saveStateProcessed: true,
        saveState: data,
      };
    case ActionTypes.FETCH_SAVE_STATE_FAILURE:
      return {
        ...state,
        fetchingSaveState: false,
        saveStateFetchFailed: true,
      };
    case ActionTypes.FETCH_SAVE_STATE_CANCEL:
      return {
        ...state,
        fetchingSaveState: false,
        saveStateProcessed: true,
        saveState: null,
      };
    case ActionTypes.SUBMIT_SAVESTATE_SUCCESS:
      return {
        ...state,
        saveStateProcessed: true,
      };
    case ActionTypes.SAVE_LOCALE_FAILURE:
      return {
        ...state,
        formIsBusy: false,
        submitting: false,
        saveLocaleError: error,
      };
    case ActionTypes.HIDE_SAVE_LOCALE_FAILURE_NOTIFICATION:
      return {
        ...state,
        saveLocaleError: null,
      };
    default:
      return nextState;
  }
};

export default configuration;
