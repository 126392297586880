import { apiRequest, postOptions } from './helper';

import { configPath } from './configuration';

const handlePreflightSubmit = values => async () => {
  const path = configPath({
    company: values.company,
    token: values.token,
  })
    .replaceAll('/config', '/preflight')
    .replaceAll('invite/', 'apply/');
  const payload = (({ firstName, middleName, lastName, dob, zipcode, ssn }) => {
    return {
      first_name: firstName,
      middle_name: middleName || '',
      last_name: lastName,
      dob: `${dob.year}-${dob.month
        .toString()
        .padStart(2, '0')}-${dob.day.toString().padStart(2, '0')}`,
      zip_code: zipcode,
      ssn,
    };
  })(values);
  try {
    return await apiRequest(path, postOptions(payload));
  } catch (error) {
    return console.log('error while requesting preflight', error);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { handlePreflightSubmit };
