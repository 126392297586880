import { analytics } from '@dashboard-experience/utils';
import { i18n } from '@international/mastodon-i18n';
import countries from 'i18n-iso-countries';
import countriesName from 'i18n-iso-countries/langs/en.json';
import { get, map, startCase, uniq } from 'lodash';

import {
  EMPLOYMENT_ANALYTICS_EVENTS,
  EMPLOYMENT_ANALYTICS_PROPERTIES,
} from './employment/analytics_events';
import { AMPLITUDE_API_KEY, ENV } from '../constants';

const services = [
  {
    name: 'amplitude',
    apiKey: AMPLITUDE_API_KEY,
  },
];

// eslint-disable-next-line import/no-mutable-exports
let checkrAnalytics;

try {
  checkrAnalytics = analytics(services);
} catch (e) {
  // eslint-disable-next-line no-console
  console.log('Analytics disabled due to cookies being disabled');
}

const ANALYTICS_EVENTS = {
  APPLICATION_SAVED: 'Apply Flow Application Saved',
  CANDIDATE_DETAILS_COMPLETED: 'Apply Flow Candidate Details Completed',
  EDUCATION_COMPLETED: 'Apply Flow Education Completed',
  EMPLOYMENT_COMPLETED: 'Apply Flow Employment Completed',
  FAQ_VIEWED: 'Apply Flow FAQ Viewed',
  DOMESTIC_FAQ_VIEWED: 'Domestic - Apply Flow FAQ Viewed',
  LANGUAGE_SELECTED: 'Apply Flow Language Selected',
  DOMESTIC_LANGUAGE_SELECTED: 'Domestic - Apply Flow Language Changed',
  RETURN_CANDIDATE_LOGIN_COMPLETED:
    'Apply Flow Return Candidate Login Completed',
  RETURN_CANDIDATE_LOGIN_VIEWED: 'Apply Flow Return Candidate Login Viewed',
  THANK_YOU_PAGE_VIEWED: 'Apply Flow Thank You Page Viewed',
  UK_IDENTITY_VERIFICATION_CONSTITUENT_COUNTRY_SELECTED:
    'Apply Flow UK Identity Verification Constituent Country Selected',
  VALIDATION_ERROR: 'Apply Flow Validation Error Viewed',
  WELCOME_PAGE_VIEWED: 'Apply Flow Welcome Page Viewed',
  DOMESTIC_WELCOME_PAGE_VIEWED:
    'Domestic - Apply Flow Personal Information Viewed',
  DOMESTIC_APPLY_FLOW_CX_PHONE_NUMBER_VIEWED:
    'Domestic - Apply Flow CX Phone Number Viewed',
  DOMESTIC_APPLY_FLOW_VALIDATION_ERROR_TRIGGERED:
    'Domestic - Apply Flow Validation Error Triggered',
  ...EMPLOYMENT_ANALYTICS_EVENTS,
  DOMESTIC_APPLY_CONFIRMATION_PAGE_EDIT_SELECTED:
    'Domestic - Apply Flow Confirmation Page Edit Selected',
  DOMESTIC_APPLY_FLOW_THANK_YOU_PAGE_VIEWED:
    'Domestic - Apply Flow Thank You Page Viewed',
};

const ANALYTICS_PROPERTIES = {
  ACCOUNT_RESOURCE_ID: 'Account Resource ID',
  ACCOUNT_URI_NAME: 'Account URI Name',
  ATTRIBUTION_SOURCE: 'Attribution Source',
  CANDIDATE_DATABASE_SOURCE: 'Candidate Database Source',
  CONSTITUENT_COUNTRY: 'Constituent Country',
  CURRENT_COUNTRY: 'Current Country',
  CUSTOM_CANDIDATE_ID: 'Custom Candidate ID',
  EDUCATION_HISTORY: 'Education History',
  EMPLOYMENT_HISTORY: 'Employment History',
  FIELDS_WITH_ERRORS: 'Fields With Errors',
  FIELDS_WITH_ADDRESS_ERRORS_DATA: 'Fields With Address Errors Data',
  INVITATION_RESOURCE_ID: 'Invitation Resource ID',
  INVITATION_SOURCE: 'Invitation Source',
  LANGUAGE: 'Language',
  NUMBER_OF_COUNTRIES: 'Number of Countries',
  OPT_IN: 'Opt In',
  PAGE_NAME: 'Page Name',
  SCREENING_ARRAY: 'Screening Array',
  USER_ID: 'User ID',
  WORK_LOCATION_COUNTRY: 'Work Location Country',
  ERROR_MESSAGE: 'Error Message',
  ERROR_CODE: 'Error Code',
  ...EMPLOYMENT_ANALYTICS_PROPERTIES,
};

const COUNTRIES_IN_US_DATA_CENTER = ['AS', 'GU', 'PH', 'PR', 'SG', 'US', 'VI'];

function getAnalyticsPageName(page) {
  const normalizedPageName = startCase(page.replace('page-', ''));
  const amplitudeNameMap = {
    'Id Redirect': 'Canada Identity Verification Bridge Page',
    'Id Verification': 'UK Identity Verification Bridge Page',
    'Motor Vehicle Report': "Driver's License Information",
    'Save State Login Form': 'Return Candidate Login',
    Welcome: 'Welcome Page',
    'Personal Information': 'Personal Information',
    'Clinic Selection': 'Clinic Selection',
    californiaDisclosure: 'California Disclosure',
    'Pro License Verification': 'Professional License Verification',
    yourRights: 'Your Rights',
  };
  return amplitudeNameMap[normalizedPageName] || normalizedPageName;
}

function getAnalyticsScreeningName(screening) {
  const amplitudeNameMap = {
    canada_bc_enhanced_criminal: 'Canada BC Enhanced Criminal',
    criminal: 'International Criminal',
    education: 'International Education',
    employment: 'International Employment',
    global: 'Global Watchlist',
    identity_document_validation: 'International Identity Document Validation',
    media_search: 'Adverse Media',
    motor_vehicle_report: 'International Motor Vehicle Report',
  };
  return amplitudeNameMap[screening] || startCase(screening);
}

function getCurrentCountry(state) {
  const addresses = get(state, 'form.intlForm.values.address', []);
  const currentAddress = addresses.find(x => x.current_address);
  const countryCode = currentAddress ? currentAddress.country_code : 'US';
  countries.registerLocale(countriesName);
  return countries.getName(countryCode, 'en');
}

function getInvitationSource(source) {
  const invitationSourceMap = {
    api: 'API',
  };
  if (invitationSourceMap[source]) {
    return invitationSourceMap[source];
  }
  if (source) {
    return source.replace(/\b(\w)/g, s => s.toUpperCase());
  }
  return undefined;
}

function getLanguageName(state) {
  const locale = get(state, 'i18n.locale');
  const isInternational =
    get(state, 'configuration.configuration.format') === 'international';
  const supportedLanguages = isInternational
    ? i18n.getSupportedLocales(ENV)
    : get(state, 'configuration.configuration.layout.supportedLanguages');
  return supportedLanguages[locale];
}

function getNumberOfCountries(state) {
  const addresses = get(state, 'form.intlForm.values.address', []);
  return uniq(map(addresses, 'country_code')).length;
}

function getSubdivisionCountryName(countryCode) {
  const countryMap = {
    'GB-ENG': 'England',
    'GB-NIR': 'Northern Ireland',
    'GB-SCT': 'Scotland',
    'GB-WLS': 'Wales',
  };
  return countryMap[countryCode] || countryCode;
}

function isSimpleObject(value) {
  return value && typeof value === 'object' && value.constructor === Object;
}

function flatten(obj) {
  // if not an object, return empty object
  if (!isSimpleObject(obj)) return {};

  return Object.entries(obj).reduce((acc, [key, value]) => {
    // if value is an object, flatten it
    if (isSimpleObject(value)) {
      // recursively flatten nested objects
      const nested = flatten(value);
      // append nested keys to parent key
      Object.entries(nested).forEach(([nestedKey, nestedValue]) => {
        acc[`${key}.${nestedKey}`] = nestedValue;
      });
    } else {
      // if value is not an object, append it to parent key
      acc[key] = value;
    }

    return acc;
  }, {});
}

function formatSyncErrors(errors) {
  return [
    ...new Set(
      Object.keys(flatten(errors)).map(key => key.replace(/\.\d+\./, '.')),
    ),
  ]
    .map(startCase)
    .sort();
}

function filterSyncErrors(errors, state) {
  const noAliasesKey = 'form.intlForm.values.scoped_requirements.CA.no_aliases';
  const noAliasesSelected = get(state, noAliasesKey);
  const noCanadaViolationsKey =
    'form.intlForm.values.scoped_requirements.CA.no_canada_violations';
  const noCanadaViolationsSelected = get(state, noCanadaViolationsKey);
  return errors.filter(error => {
    if (noAliasesSelected) {
      if (error === 'Scoped Requirements CA Aliases Previous Name') {
        return false;
      }
    }
    if (noCanadaViolationsSelected) {
      if (
        [
          'Scoped Requirements CA Violations Court Location',
          'Scoped Requirements CA Violations Date Of Sentence',
          'Scoped Requirements CA Violations Offence',
        ].includes(error)
      ) {
        return false;
      }
    }
    return true;
  });
}

function getEventPropertiesFromStore(title, state) {
  const eventProps = {};
  if (title === ANALYTICS_EVENTS.CANDIDATE_DETAILS_COMPLETED) {
    eventProps[ANALYTICS_PROPERTIES.CURRENT_COUNTRY] = getCurrentCountry(state);
  }
  if (
    title ===
    ANALYTICS_EVENTS.UK_IDENTITY_VERIFICATION_CONSTITUENT_COUNTRY_SELECTED
  ) {
    eventProps[
      ANALYTICS_PROPERTIES.CONSTITUENT_COUNTRY
    ] = getSubdivisionCountryName(
      get(
        state,
        'form.intlForm.values.scoped_requirements.GB.constituent_country',
      ),
    );
  }
  if (title === ANALYTICS_EVENTS.EDUCATION_COMPLETED) {
    eventProps[ANALYTICS_PROPERTIES.EDUCATION_HISTORY] = !get(
      state,
      'form.intlForm.values.no_education_history',
    );
  }
  if (
    title === ANALYTICS_EVENTS.EMPLOYMENT_COMPLETED ||
    title === ANALYTICS_EVENTS.DOMESTIC_EMPLOYMENT_COMPLETED
  ) {
    eventProps[ANALYTICS_PROPERTIES.EMPLOYMENT_HISTORY] = !get(
      state,
      'form.intlForm.values.no_employment_history',
    );
    eventProps[ANALYTICS_PROPERTIES.ARGYLE_EMPLOYMENTS_USED] = !!get(
      state,
      'employmentScreening.argyleAccountEmployments',
    )[0];
  }
  if (title === ANALYTICS_EVENTS.ARGYLE_POLLING_COMPLETED) {
    eventProps[ANALYTICS_PROPERTIES.ARGYLE_RESULTS_PROVIDED] = get(
      state,
      'employmentScreening.argyleCurrentState',
    );
  }
  if (title === ANALYTICS_EVENTS.VALIDATION_ERROR) {
    const errors = get(state, 'form.intlForm.syncErrors');
    const formattedSyncErrors = formatSyncErrors(errors);
    const filteredSyncErrors = filterSyncErrors(formattedSyncErrors, state);
    // This part is added for finding a bug EINTSC-1934
    const addressFields = ['Address Start Date', 'Address End Date', 'Address'];
    if (filteredSyncErrors.some(field => addressFields.includes(field))) {
      eventProps[ANALYTICS_PROPERTIES.FIELDS_WITH_ADDRESS_ERRORS_DATA] = {
        start_date_0: get(state, 'form.intlForm.values.address[0].start_date'),
        end_date_0: get(state, 'form.intlForm.values.address[0].end_date'),
        start_date_1: get(state, 'form.intlForm.values.address[1].start_date'),
        end_date_1: get(state, 'form.intlForm.values.address[1].end_date'),
        start_date_2: get(state, 'form.intlForm.values.address[2].start_date'),
        end_date_2: get(state, 'form.intlForm.values.address[2].end_date'),
      };
    }
    eventProps[ANALYTICS_PROPERTIES.FIELDS_WITH_ERRORS] = filteredSyncErrors;
  }

  if (
    title === ANALYTICS_EVENTS.DOMESTIC_APPLY_FLOW_VALIDATION_ERROR_TRIGGERED
  ) {
    const errors = get(state, 'form.applyform.syncErrors');
    const formattedSyncErrors = formatSyncErrors(errors);
    const filteredSyncErrors = filterSyncErrors(formattedSyncErrors, state);
    eventProps[ANALYTICS_PROPERTIES.FIELDS_WITH_ERRORS] = filteredSyncErrors;
  }

  eventProps[ANALYTICS_PROPERTIES.INVITATION_RESOURCE_ID] = get(
    state,
    'configuration.configuration.invitation.id',
  );
  if (
    title === ANALYTICS_EVENTS.WELCOME_PAGE_VIEWED ||
    title === ANALYTICS_EVENTS.DOMESTIC_WELCOME_PAGE_VIEWED
  ) {
    eventProps[ANALYTICS_PROPERTIES.INVITATION_SOURCE] = getInvitationSource(
      get(state, 'configuration.configuration.invitation.source'),
    );
  }
  if (
    title === ANALYTICS_EVENTS.LANGUAGE_SELECTED ||
    title === ANALYTICS_EVENTS.DOMESTIC_LANGUAGE_SELECTED
  ) {
    eventProps[ANALYTICS_PROPERTIES.LANGUAGE] = getLanguageName(state);
  }
  if (title === ANALYTICS_EVENTS.CANDIDATE_DETAILS_COMPLETED) {
    eventProps[ANALYTICS_PROPERTIES.NUMBER_OF_COUNTRIES] = getNumberOfCountries(
      state,
    );
  }
  if (
    [
      ANALYTICS_EVENTS.APPLICATION_SAVED,
      ANALYTICS_EVENTS.LANGUAGE_SELECTED,
      ANALYTICS_EVENTS.VALIDATION_ERROR,
    ].includes(title)
  ) {
    eventProps[ANALYTICS_PROPERTIES.PAGE_NAME] = getAnalyticsPageName(
      get(state, 'navigation.currentPageKey'),
    );
  }
  return eventProps;
}

function getUserPropertiesFromStore(state) {
  const userProps = {
    [ANALYTICS_PROPERTIES.USER_ID]: get(
      state,
      'configuration.configuration.candidate.resource_id',
      get(
        state,
        'configuration.configuration.candidate.id',
        get(state, 'form.applyform.response.candidate.id'),
      ),
    ),
  };
  const dataResidencyLocation = get(
    state,
    'configuration.configuration.candidate.data_residency_location',
  );
  userProps[ANALYTICS_PROPERTIES.ACCOUNT_RESOURCE_ID] = get(
    state,
    'configuration.configuration.account.id',
  );
  userProps[ANALYTICS_PROPERTIES.ACCOUNT_URI_NAME] = get(
    state,
    'configuration.routeParams.company',
  );
  userProps[
    ANALYTICS_PROPERTIES.CANDIDATE_DATABASE_SOURCE
  ] = COUNTRIES_IN_US_DATA_CENTER.includes(dataResidencyLocation) ? 'US' : 'EU';
  userProps[ANALYTICS_PROPERTIES.CUSTOM_CANDIDATE_ID] = get(
    state,
    'configuration.configuration.candidate.custom_id',
  );
  userProps[ANALYTICS_PROPERTIES.SCREENING_ARRAY] = get(
    state,
    'configuration.configuration.schema.request.screening_types',
    [],
  ).map(getAnalyticsScreeningName);
  userProps[ANALYTICS_PROPERTIES.WORK_LOCATION_COUNTRY] = countries.getName(
    dataResidencyLocation,
    'en',
  );
  return userProps;
}

function getEventNameForDomesticPage(pageName, eventName) {
  const newPage = pageName === 'welcome' ? 'Personal Information' : pageName;

  const pageAnalyticsName = getAnalyticsPageName(newPage);
  return `Domestic - Apply Flow ${pageAnalyticsName} ${eventName}`;
}

function getEventNameForDomesticPageViewed(pageName) {
  return getEventNameForDomesticPage(pageName, 'Viewed');
}

function getEventNameForDomesticPageCompleted(pageName) {
  return getEventNameForDomesticPage(pageName, 'Completed');
}

export {
  ANALYTICS_EVENTS,
  ANALYTICS_PROPERTIES,
  getAnalyticsPageName,
  getEventPropertiesFromStore,
  getUserPropertiesFromStore,
  getEventNameForDomesticPageViewed,
  getEventNameForDomesticPageCompleted,
};

export default checkrAnalytics;
