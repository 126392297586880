/* eslint-disable camelcase */
import { reduce, intersection } from 'lodash/fp';

import { FORM_BUSY, FORM_IDLE } from './navigation';
import {
  requestConfigurationUpdate,
  SET_SCOPED_REQUIREMENTS,
} from './configuration';
import getUniqNonNullableByField from '../lib/utils/getUniqNonNullableByField';

const SET_HAS_INTERNATIONAL_ADDRESSES = 'SET_HAS_INTERNATIONAL_ADDRESSES';

const setHasInternationalAddresses = hasInternationalAddresses => dispatch => {
  dispatch({
    type: SET_HAS_INTERNATIONAL_ADDRESSES,
    hasInternationalAddresses,
  });
};

const SET_SHOW_CURRENT_ADDRESS_CHECKBOX = 'SET_SHOW_CURRENT_ADDRESS_CHECKBOX';

const setShowCurrentAddressCheckbox = showCurrentAddressCheckbox => dispatch => {
  dispatch({
    type: SET_SHOW_CURRENT_ADDRESS_CHECKBOX,
    showCurrentAddressCheckbox,
  });
};

const SET_ADDRESS_HISTORY_REQUIREMENT_SATISFIED =
  'SET_ADDRESS_HISTORY_REQUIREMENT_SATISFIED';

const setAddressHistoryRequirementSatisfied = addressHistoryRequirementSatisfied => dispatch => {
  dispatch({
    type: SET_ADDRESS_HISTORY_REQUIREMENT_SATISFIED,
    addressHistoryRequirementSatisfied,
  });
};

const requestHasInternationalAddressesConfigUpdate = () => (
  dispatch,
  getState,
) => {
  const { hasInternationalAddresses } = getState().internationalAddressHistory;
  const {
    internationalAddresses,
    scoped_requirements = {},
  } = getState().form?.applyform?.values;
  const internationalCountries = getUniqNonNullableByField(
    internationalAddresses,
    'countryCode',
  );

  // reset scoped_requirements based on address changes
  dispatch({
    type: SET_SCOPED_REQUIREMENTS,
    data: hasInternationalAddresses
      ? reduce(
          (requirements, countryCode) => ({
            ...requirements,
            [countryCode]: scoped_requirements[countryCode],
          }),
          {},
          intersection(
            Object.keys(scoped_requirements),
            internationalCountries,
          ),
        )
      : {},
  });

  dispatch({ type: FORM_BUSY });
  requestConfigurationUpdate({
    params: { hasInternationalAddresses, internationalCountries },
    callback: () => {
      dispatch({ type: FORM_IDLE });
    },
  })(dispatch, getState);
};

export {
  SET_HAS_INTERNATIONAL_ADDRESSES,
  SET_SHOW_CURRENT_ADDRESS_CHECKBOX,
  SET_ADDRESS_HISTORY_REQUIREMENT_SATISFIED,
  setHasInternationalAddresses,
  setShowCurrentAddressCheckbox,
  setAddressHistoryRequirementSatisfied,
  requestHasInternationalAddressesConfigUpdate,
};
