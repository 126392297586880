const TOGGLE_CHANGE = 'TOGGLE_CHANGE';
const ADD_ADDITIONAL_QUESTIONNAIRE_ENTRY = 'ADD_ADDITIONAL_QUESTIONNAIRE_ENTRY';

const toggleChange = (expanded, configuration) => ({
  type: TOGGLE_CHANGE,
});

const addAdditionalQuestionnaireEntry = configuration => ({
  type: ADD_ADDITIONAL_QUESTIONNAIRE_ENTRY,
});

const handleToggleChange = expanded => (dispatch, getState) => {
  const retrievedState = getState();
  const {
    configuration: { configuration },
  } = retrievedState;
  dispatch(toggleChange(expanded, configuration));
};

const handleAdditionalQuestionnaireEntry = () => (dispatch, getState) => {
  const retrievedState = getState();
  const {
    configuration: { configuration },
  } = retrievedState;
  dispatch(addAdditionalQuestionnaireEntry(configuration));
};

export { handleAdditionalQuestionnaireEntry, handleToggleChange };
