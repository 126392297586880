import { IS_TEST_MODE } from '../constants';

// eslint-disable-next-line import/no-mutable-exports
let defaultValues = {};
let intlDefaultValuesTemp = {};

if (process.env.NODE_ENV === 'development') {
  defaultValues = {
    firstName: 'John',
    middleName: 'J',
    lastName: 'Doe',
    dob: {
      year: '1990',
      month: '3',
      day: '11',
    },
    zipcode: '94110',
    ssn: '123-12-1234',
    ssnConfirmation: '123-12-1234',
    phone: '415-111-1111',
    email: 'john@checkr.com',
    dlState: 'CA',
    dlNumber: 'A1234567',
    dlNumberConfirmation: 'A1234567',
    fcra: true,
    disclosure: true,
    noEducationHistory: false,
    noEmploymentHistory: false,
    showNoHistoryOption: true,
    criminalSelfDisclosureAccepted: true,
    signature: 'John J Doe',
    criminalSelfDisclosureEntries: [
      {
        state: 'CA',
        county: 'San Francisco',
        date: '10/2000',
        offenseLevel: 'misdemeanor',
        offenseCategory: 'larceny',
        sentence: '2 years in prison',
        timeServed: '1 year + probation',
      },
    ],
    currentAddress: {
      street: '1 Montgomery St.',
      city: 'San Francisco',
      zipcode: '94110',
      state: 'CA',
      startDate: '01/2001',
    },
    internationalAddresses: [
      {
        countryCode: 'GB',
        street: '221B Baker Street',
        city: 'London',
        state: 'UK',
        zipcode: 'NW1 6XE',
        startDate: '11/1959',
        endDate: '11/2018',
      },
    ],
    reporterFullName: 'Sterling Archer',
    reporterEmail: 'sa@isis.com',
  };

  intlDefaultValuesTemp = {
    first_name: 'John',
    middle_name: 'H',
    no_middle_name: false,
    last_name: 'Watson',
    dob: '1900-01-02',
    phone: '415-111-1111',
    email: 'john.watson@checkr.com',
    country_of_birth: 'DZ',
    city_of_birth: 'Algiers',
    passport: {
      country: 'GB',
      number: 'haoetnusaoe',
      document: {
        filename: 'aoheutns',
        mimetype: 'htueonas',
      },
    },
    international_consent_form: {
      filename: 'aoheutns',
      mimetype: 'htueonas',
    },
    address: [
      {
        country_code: 'PH',
        street: '221B Baker Street',
        city: 'London',
        state: 'UK',
        zipcode: 'NW1 6XE',
        start_date: '2018-12',
        current_address: true,
      },
      {
        country_code: 'DE',
        street: '221B Baker Street',
        city: 'London',
        state: 'UK',
        zipcode: 'NW1 6XE',
        start_date: '2018-10',
        end_date: '2018-12',
      },
      {
        country_code: 'GB',
        street: '221B Baker Street',
        city: 'London',
        state: 'UK',
        zipcode: 'NW1 6XE',
        start_date: '2018-09',
        end_date: '2018-10',

        current_address: false,
      },
    ],
    employers: [
      {
        do_not_contact: false,
        name: 'Employer',
        position: 'Worker',
        address: '413 Main St.',
        city: 'City',
        state: 'State',
        zipcode: '12345',
        country: 'GB',
        phone_number: '1231231234',
        manager_name: 'Manager',
        manager_title: 'Title',
        manager_email: 'Email@email.com',
        manager_phone: '1231231234',
        start_date: '11/1998',
        end_date: '12/2005',
      },
    ],
    fcra: true,
    disclosure: true,
    authorization: true,
    signature: 'John H Watson',
    scoped_requirements: {
      GB: {
        national_id: {
          country: 'GB',
        },
      },
      FR: {
        additional_instructions: {
          acknowledged: true,
        },
        city_of_birth: 'Nice',
        country_of_birth: 'FR',
        international_consent_form: {
          filename: 'aoheutns',
          mimetype: 'htueonas',
          url: 'uehotansuo',
        },
      },
    },
    citizenship_identity: {
      name: 'passport_number',
      value: 'aoeu',
    },
    citizenship: 'GB',
    education: [
      {
        name: 'John H Watson',
        address: 'Address',
        city: 'City',
        state: 'State',
        zipcode: '12345',
        country: 'PH',
        phone: '1231231234',
        name_on_diploma: 'John H Watson',
        start_date: '11/1999',
        end_date: '12/2002',
        year_awarded: '2002',
        degree: 'BS',
        major: 'Science',
      },
    ],
    uiSpecificInformation: {
      oneOfInformation: 'passport',
    },
    driver_licenses: [
      {
        country_of_issue: 'CA',
        province_of_issue: 'AB',
      },
    ],
  };
}

if (IS_TEST_MODE) {
  defaultValues = {
    recaptchaCode: 'xxxxxxxxxxxxxxx',
  };
}

export const intlDefaultValues = intlDefaultValuesTemp;

export default defaultValues;
