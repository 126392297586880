import { map } from 'lodash';
import { apiRequest } from './helper';

const requestTypeaheadSchools = () => {
  return apiRequest('schools').then(response => {
    const { higherEducationSchools, highSchools } = response;

    return {
      higherEducationSchools: map(higherEducationSchools, s => s.name),
      highSchools: map(highSchools, s => s.name),
    };
  });
};

export { requestTypeaheadSchools as default };
