import { API_STATUS_PROXY_URL } from '../constants';

/*
 * Action Types
 */

export const API_STATUS_REQUEST = 'API_STATUS_REQUEST';

export const apiStatusRequest = () => ({
  type: API_STATUS_REQUEST,
});

export const API_STATUS_SUCCESS = 'API_STATUS_SUCCESS';

export const apiStatusSuccess = incidents => ({
  type: API_STATUS_SUCCESS,
  incidents,
});

export const API_STATUS_FAILURE = 'API_STATUS_FAILURE';

export const apiStatusFailure = error => ({
  type: API_STATUS_FAILURE,
  error,
});

/*
 * Actions
 */

export const requestApiStatus = () => {
  return dispatch => {
    dispatch(apiStatusRequest());

    return fetch(API_STATUS_PROXY_URL)
      .then(res => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
      .then(data => {
        const { incidents } = data;
        dispatch(apiStatusSuccess({ incidents }));
      })
      .catch(error => {
        dispatch(apiStatusFailure(error));
      });
  };
};
