const EMPLOYMENT_ANALYTICS_EVENTS = {
  DOMESTIC_EMPLOYMENT_VERIFICATION_STARTED:
    'Domestic - Employment Verification Started',
  DOMESTIC_EMPLOYMENT_COMPLETED: 'Domestic - Apply Flow Employment Completed',
  ARGYLE_LEARN_MORE_VIEWED: 'Employment Verification Learn More Modal Viewed',
  ARGYLE_PAYROLL_PROVIDER_ACCOUNT_CONNECTED:
    'Employer/Payroll Provider Account Connected',
  ARGYLE_PAYROLL_PROVIDER_ACCOUNT_CONNECTION_ERROR:
    'Employer/Payroll Provider Account Connection Error',
  ARGYLE_POLLING_STARTED:
    'Employment Verification Argyle Result Polling Started',
  ARGYLE_POLLING_COMPLETED:
    'Employment Verification Argyle Results Polling Completed',
  ARGYLE_POLLING_TIMEOUT:
    'Employment Verification Argyle Results Polling Timeout',
  ARGYLE_REVIEW_ACCEPTED: 'Employment Verification Review Results Accepted',
  ARGYLE_REVIEW_CANCELED: 'Employment Verification Review Results Canceled',
  ARGYLE_SELECTED: 'Employment Verification Quick Verify Selected',
  MANUAL_SELECTED: 'Employment Verification Manual Entry Selected',
};

const EMPLOYMENT_ANALYTICS_PROPERTIES = {
  EMPLOYMENT_ID: 'Employment Id',
  ARGYLE_RESULTS_PROVIDED: 'Results Provided',
  ARGYLE_ACCOUNT_ID: 'Argyle Account Id',
  ARGYLE_ACCOUNT_IDS: 'Argyle Account Ids',
  ARGYLE_EMPLOYMENT_IDS: 'Argyle Employment Ids',
  ARGYLE_EMPLOYMENTS_USED: 'Quick Verify Inputs Used',
  ARGYLE_LEARN_MORE_SOURCE: 'Source',
  ARGYLE_SELECTED_ARGYLE_EMPLOYMENT_COUNT:
    'Number of Selected Argyle Employments',
  ARGYLE_SELECTED_ARGYLE_EMPLOYER_NAMES:
    'Employer Names with Start Date of Selected Employers',
  ARGYLE_SELECTED_MANUAL_EMPLOYMENT_COUNT:
    'Number of Selected Manual Entry Employments',
  ARGYLE_VERIFIED_EMPLOYMENT_COUNT: 'Number of Verified Employments',
  ARGYLE_UNVERIFIED_EMPLOYMENT_COUNT: 'Number of Unverified Employments',
  ARGYLE_OUTSIDE_LOOKBACK_EMPLOYMENT_COUNT:
    'Number of Outside Lookback Period Employments',
  ARGYLE_UNVERIFIED_AND_OUTSIDE_LOOKBACK_EMPLOYMENT_COUNT:
    'Number of Unverified and Outside Lookback Period Employments',
  ARGYLE_USER_ID: 'Argyle User Id',
  MAX_ALLOWED_EMPLOYER_COUNT: 'Maximum Allowed Employers',
};

const EMPLOYMENT_ANALYTICS_ARGYLE_LEARN_MORE_SOURCES = {
  SELECTION_CARD: 'selection card',
  MANUAL_ENTRY_FORM: 'manual entry form',
};

export {
  EMPLOYMENT_ANALYTICS_EVENTS,
  EMPLOYMENT_ANALYTICS_PROPERTIES,
  EMPLOYMENT_ANALYTICS_ARGYLE_LEARN_MORE_SOURCES,
};
