import * as ActionTypes from '../actions';
import defaultValues from './defaultValues';

export const initialState = {
  submitting: false,
  errors: {},
  status: null,
  values: defaultValues,
  response: {},
};

const applyform = (state = initialState, action) => {
  const {
    configuration,
    errors,
    refreshing,
    routeParams,
    type,
    data,
    updateCandidate,
  } = action;

  switch (type) {
    case ActionTypes.FETCH_CONFIG_REQUEST:
      return {
        ...state,
        values: {
          ...state.values,
          token: routeParams.token,
          customId: routeParams.customId,
          geoId: routeParams.geoId,
          invitationId: routeParams.invitationId,
          order: routeParams.order,
          node: routeParams.segment_id || routeParams.node_id,
          work_locations: [
            {
              state: routeParams.wl_state,
              city: routeParams.wl_city,
              country: routeParams.wl_country,
            },
          ],
        },
      };
    case ActionTypes.FETCH_CONFIG_SUCCESS:
      return {
        ...state,
        values: {
          ...state.values,
          ...(refreshing && !updateCandidate ? {} : configuration.candidate),
          accountId: configuration.account.id,
        },
      };
    case ActionTypes.SUBMIT_FORM_REQUEST:
      return {
        ...state,
        submitting: true,
      };
    case ActionTypes.SUBMIT_FORM_FAILURE:
      return {
        ...state,
        submitting: false,
        submitSuccess: false,
        errors,
      };
    case ActionTypes.SUBMIT_FORM_SUCCESS:
      return {
        ...state,
        submitting: false,
        submitSuccess: true,
        response: data,
      };
    case ActionTypes.SET_SCOPED_REQUIREMENTS:
      return {
        ...state,
        values: {
          ...state.values,
          scoped_requirements: data,
        },
      };
    default:
      return state;
  }
};

export default applyform;
