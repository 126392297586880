/* eslint-disable camelcase */
import { setLocale } from 'react-redux-i18n';
import {
  change,
  formValueSelector,
  getFormValues,
  SubmissionError,
} from 'redux-form';
import { camelizeKeys } from 'humps';
import * as Sentry from '@sentry/react';
import { i18n, moment } from '@international/mastodon-i18n';

import resources from 'lib/translations/resources';
import { isArray, set, map } from 'lodash';
import 'url-polyfill';

import { ANALYTICS_EVENTS } from '../lib/analytics';
import Emitter, { REQUESTED_INTL_CONFIG } from '../lib/utils/emitter';

import { trackAnalyticsEvent } from './analytics';

import {
  apiRequest,
  apiRequestAsIs,
  buildSearchQuery,
  parseErrorResponse,
  postOptions,
  processIntlLocations,
  isJSON,
  formatResponseData,
} from './helper';

import {
  isCurrentAddressDomestic,
  formatInternationalRequirementsSchema,
} from '../international/FormLogic/Utils';
import { ISO_DATE_FORMAT } from '../constants';

const FETCH_CONFIG_FAILURE = 'FETCH_CONFIG_FAILURE';
const FETCH_CONFIG_REQUEST = 'FETCH_CONFIG_REQUEST';
const FETCH_CONFIG_SUCCESS = 'FETCH_CONFIG_SUCCESS';
const FETCH_INTL_REQUIREMENTS_CONFIG = 'FETCH_INTL_REQUIREMENTS_CONFIG';
const FETCH_INTL_REQUIREMENTS_CONFIG_FAILURE =
  'FETCH_INTL_REQUIREMENTS_CONFIG_FAILURE';
const SET_PARTIAL_SSN = 'SET_PARTIAL_SSN';
const FETCH_SAVE_STATE_REQUEST = 'INT_SAVE_STATE_REQUEST';
const FETCH_SAVE_STATE_SUCCESS = 'INT_SAVE_STATE_SUCCESS';
const FETCH_SAVE_STATE_FAILURE = 'INT_SAVE_STATE_FAILURE';
const FETCH_SAVE_STATE_CANCEL = 'INT_SAVE_STATE_CANCEL';
const SAVE_LOCALE_FAILURE = 'SAVE_LOCALE_FAILURE';
const SET_SCOPED_REQUIREMENTS = 'SET_SCOPED_REQUIREMENTS';

const formValues = formValueSelector('applyform');
const intlForm = getFormValues('intlForm');

const fetchConfiguration = (routeParams, refreshing = false) => ({
  type: FETCH_CONFIG_REQUEST,
  routeParams,
  refreshing,
});

const fetchIntlRequirementsConfig = () => ({
  type: FETCH_INTL_REQUIREMENTS_CONFIG,
});

const fetchIntlRequirementsConfigFailure = () => ({
  type: FETCH_INTL_REQUIREMENTS_CONFIG_FAILURE,
});

const fetchConfigSuccess = (
  configuration,
  refreshing = false,
  updateCandidate = false,
) => ({
  type: FETCH_CONFIG_SUCCESS,
  configuration,
  refreshing,
  updateCandidate,
});

const fetchConfigFailure = error => ({
  type: FETCH_CONFIG_FAILURE,
  error,
});

const setPartialSsn = partialSsn => ({
  type: SET_PARTIAL_SSN,
  partialSsn,
});

const forwardToRedirect = redirect => {
  window.location = redirect;
  return window.location;
};

const intlRequirementsPath = (company, token, isTest) =>
  `invite/${company}/${token}/requirements?test=${isTest}`;

const intlLocalePath = (company, token) => `invite/${company}/${token}/locale`;

const appointmentPath = ({
  appointmentId,
  nonElectronicClinic,
  hasSelectedExams,
}) => {
  return `health_screenings/appointments/${appointmentId}/config${buildSearchQuery(
    { nonElectronicClinic, hasSelectedExams },
  )}`;
};

const applyPath = ({
  company,
  dlState,
  customId,
  geoId,
  invitationId,
  hash,
  isTest,
  lang,
  order,
  zipcode,
  nonElectronicClinic,
  hasSelectedExams,
  hasInternationalAddresses,
  internationalCountries,
  wl_city,
  wl_state,
  wl_country,
  node_id,
  oneEightHundredFlowSelected,
}) => {
  const searchQuery = {
    customId,
    dlState,
    geoId,
    invitationId,
    lang,
    order,
    test: isTest,
    zipcode,
    nonElectronicClinic,
    hasSelectedExams,
    hasInternationalAddresses,
    internationalCountries,
    node: node_id,
    'work_location[city]': wl_city,
    'work_location[state]': wl_state,
    'work_location[country]': wl_country,
    oneEightHundredFlowSelected,
  };

  return `apply/${company}/${hash}/config${buildSearchQuery(searchQuery)}`;
};

const invitePath = ({
  company,
  dlState,
  isTest,
  lang,
  token,
  zipcode,
  partialSsn,
  notMe,
  nonElectronicClinic,
  hasSelectedExams,
  hasInternationalAddresses,
  internationalCountries,
}) => {
  const searchQuery = {
    dlState,
    lang,
    test: isTest,
    zipcode,
    partialSsn,
    notMe,
    nonElectronicClinic,
    hasSelectedExams,
    hasInternationalAddresses,
    internationalCountries,
  };

  return `invite/${company}/${token}/config${buildSearchQuery(searchQuery)}`;
};

const configPath = params => {
  if (params.token) {
    return invitePath(params);
  }

  return applyPath(params);
};

const handleConfigError = (error, dispatch) => {
  if (error.response) {
    parseErrorResponse(error).then(({ errors }) => {
      // eslint-disable-next-line no-param-reassign
      error.message = isArray(errors)
        ? errors.map(e => i18n.getConstStr('errors', e))
        : i18n.getConstStr('errors', errors);
      dispatch(fetchConfigFailure(error));
    });
  } else {
    throw error;
  }
};

const updateLocale = (
  lang,
  routeParams = {},
  updateServer = false,
  candidate = {},
  isInternational = false,
) => (dispatch, getState) => {
  document.getElementsByTagName('html')[0].setAttribute('lang', lang);
  dispatch(
    setLocale(getResourceLocale(lang, candidate?.data_residency_location)),
  );
  if (candidate?.locale) {
    i18n.setLocale({
      value: lang,
      resources: resources(),
      country: candidate?.data_residency_location,
    });
  } else {
    i18n.setLocale({
      value: lang,
      resources: resources(),
      country: i18n.getWorkLocation(),
    });
    moment.locale(lang);
  }

  if (updateServer && routeParams && routeParams.company && routeParams.token) {
    const localeData = {
      locale: lang,
    };

    if (isInternational) {
      apiRequestAsIs(
        intlLocalePath(routeParams.company, routeParams.token),
        postOptions(localeData),
      )
        .then(json => {
          dispatch(trackAnalyticsEvent(ANALYTICS_EVENTS.LANGUAGE_SELECTED));
        })
        .catch(error =>
          dispatch({
            type: SAVE_LOCALE_FAILURE,
            errors: i18n.getStr('errors.failedToUpdateCandidateLocale'),
          }),
        );
    } else {
      dispatch(
        trackAnalyticsEvent(ANALYTICS_EVENTS.DOMESTIC_LANGUAGE_SELECTED),
      );
    }
  }

  const url = new URL(window.location);
  if (url.searchParams.has('lang')) {
    url.searchParams.delete('lang');
    window.history.pushState({}, null, url);
  }
};

const updatePartialSsn = partialSsn => dispatch => {
  dispatch(setPartialSsn(partialSsn));
};

const transformStartEndDates = objData => ({
  ...objData,
  start_date: objData?.start_date
    ? i18n.getLocalizedMonthYear(objData.start_date)
    : '',
  end_date: objData?.end_date
    ? i18n.getLocalizedMonthYear(objData.end_date)
    : '',
});

const requestConfiguration = options => dispatch => {
  const { refreshing = false, callback, updateCandidate = false } = options;
  let { params } = options;

  dispatch(fetchConfiguration(params, refreshing));

  const path = configPath(params);
  return apiRequestAsIs(path)
    .then(json => {
      const locale = !isJSON(json?.candidate?.locale)
        ? json?.candidate?.locale
        : JSON.parse(json?.candidate?.locale || '{}')?.language;
      const candidate = json?.candidate;
      // Update Candidate locale when current locale and requested locale from url is not same
      if (
        !params.updateServer &&
        locale &&
        params?.lang !== undefined &&
        params?.lang !== locale
      ) {
        params = {
          ...params,
          updateServer: true,
        };
      }
      dispatch(
        updateLocale(
          params?.lang || locale || json.lang,
          params,
          params.updateServer,
          candidate,
          json.format === 'international',
        ),
      );
      const data =
        json.format === 'international'
          ? formatResponseData(json)
          : camelizeKeys(json);
      // We do not want to camelize/decamelize the dynamic keys being used for the Intl flow.
      // So we fork the logic here and do not camelize responses with a schema (International specific result)
      map(json.pages, ({ components }, pIdx) => {
        map(components, ({ props }, cIdx) => {
          if (props?.schema) {
            set(
              data,
              `pages[${pIdx}].components[${cIdx}].props.schema`,
              props.schema,
            );
          }
        });
      });
      const formattedSchema = formatInternationalRequirementsSchema(data);

      dispatch(
        fetchConfigSuccess(formattedSchema, refreshing, updateCandidate),
      );
      if (callback) callback();
    })
    .catch(error => handleConfigError(error, dispatch));
};

const fetchHealthScreeningConfigSuccess = configuration => ({
  type: FETCH_CONFIG_SUCCESS,
  configuration,
  refreshing: false,
});

const requestAppointmentConfiguration = ({
  params,
  callback,
  refreshing = false,
}) => (dispatch, getState) => {
  const path = appointmentPath(params);
  dispatch(fetchConfiguration(params, refreshing));
  return apiRequest(path)
    .then(json => {
      const state = getState();
      const selectedLocation = formValues(state, 'location');
      const { location: currentLocation, defaultZipcode } = json;

      if (currentLocation && !selectedLocation) {
        dispatch(
          change('applyform', 'location', {
            siteId: currentLocation.siteId,
            zipcode: currentLocation.zipcode,
          }),
        );
      }
      dispatch(
        change(
          'applyform',
          'zipcode',
          currentLocation ? currentLocation.zipcode : defaultZipcode,
        ),
      );
      dispatch(fetchHealthScreeningConfigSuccess(json));
      if (callback) {
        callback();
      }
    })
    .catch(error => handleConfigError(error, dispatch));
};

const requestConfigurationUpdate = options => (dispatch, getState) => {
  const {
    params,
    refreshing = true,
    callback,
    requestorMethod = requestConfiguration,
    updateCandidate = false,
  } = options;
  const { routeParams } = getState().configuration;
  const newParams = {
    ...routeParams,
    ...params,
  };
  dispatch(
    requestorMethod({
      params: newParams,
      refreshing,
      updateCandidate,
      callback,
    }),
  );
};

const requestIntlRequirementConfig = addresses => (dispatch, getState) => {
  dispatch(fetchIntlRequirementsConfig());
  const { routeParams } = getState().configuration;
  const { company, token, isTest } = routeParams;
  const locationData = {
    locations: processIntlLocations(addresses),
    locale: i18n.getLocale(),
  };

  if (isCurrentAddressDomestic(addresses)) {
    dispatch(fetchIntlRequirementsConfigFailure());
    return Promise.reject();
  }

  return apiRequestAsIs(
    intlRequirementsPath(company, token, isTest),
    postOptions(locationData),
  )
    .then(json => {
      const data = json.schema
        ? formatInternationalRequirementsSchema(json)
        : camelizeKeys(json);
      dispatch(fetchConfigSuccess(formatResponseData(data)));
      Emitter.emit(REQUESTED_INTL_CONFIG);
    })
    .catch(error => handleConfigError(error, dispatch));
};

const fetchSaveState = () => (dispatch, getState) => {
  const data = intlForm(getState());
  const { routeParams } = getState().configuration;
  const finalPayload = {
    data: {
      ...data,
      dob: moment(
        data.dob,
        i18n.getLocalizedDateFormat(i18n.DateFormats.DATE_SLASH),
      ).format(ISO_DATE_FORMAT),
    },
    recaptcha_code: data.recaptchaCode,
  };
  const { company, token, isTest } = routeParams;
  const postPath = `invite/${company}/${token}/resume?test=${isTest}`;

  dispatch({ type: FETCH_SAVE_STATE_REQUEST });
  if (!finalPayload.recaptcha_code) {
    dispatch(fetchSaveStateFailure());
    return null;
  }

  return apiRequestAsIs(postPath, postOptions(finalPayload))
    .then(({ data: responseData }) => {
      const result = formatResponseData(responseData);
      const { address, education, employers } = result;
      const stateData = {
        ...result,
        address: map(address, transformStartEndDates),
        education: map(education, transformStartEndDates),
        employers: map(employers, transformStartEndDates),
      };
      dispatch(fetchSaveStateSuccess(stateData));
      window.scrollTo(0, 0);
      dispatch(
        trackAnalyticsEvent(ANALYTICS_EVENTS.RETURN_CANDIDATE_LOGIN_COMPLETED),
      );
    })
    .catch(error => {
      return parseErrorResponse(error).then(({ errors }) => {
        dispatch(fetchSaveStateFailure(error));
        const submissionError = new SubmissionError(errors);
        if (error.status !== 400) {
          Sentry.captureException(submissionError, {
            extra: { serverErrors: errors },
          });
        }
      });
    });
};

const fetchSaveStateCancel = () => dispatch => {
  dispatch({ type: FETCH_SAVE_STATE_CANCEL });
};

const fetchSaveStateSuccess = saveState => ({
  type: FETCH_SAVE_STATE_SUCCESS,
  data: saveState,
});

const fetchSaveStateFailure = error => ({
  type: FETCH_SAVE_STATE_FAILURE,
  error,
});

const isFound = (object, locale) => {
  return !!object[locale];
};

// return locale if in resources
const getResourceLocale = (locale, country) => {
  let resourceLocale =
    locale?.length === 2 && country ? `${locale}_${country}` : locale;
  if (!isFound(resources(), resourceLocale)) {
    resourceLocale = resourceLocale?.slice(0, 2);
    if (!isFound(resources(), resourceLocale)) {
      resourceLocale = i18n.defaultLocale;
    }
  }
  return resourceLocale;
};

export {
  FETCH_CONFIG_FAILURE,
  FETCH_CONFIG_REQUEST,
  FETCH_CONFIG_SUCCESS,
  SET_PARTIAL_SSN,
  FETCH_SAVE_STATE_REQUEST,
  FETCH_SAVE_STATE_SUCCESS,
  FETCH_SAVE_STATE_FAILURE,
  FETCH_SAVE_STATE_CANCEL,
  FETCH_INTL_REQUIREMENTS_CONFIG,
  FETCH_INTL_REQUIREMENTS_CONFIG_FAILURE,
  SAVE_LOCALE_FAILURE,
  SET_SCOPED_REQUIREMENTS,
  intlRequirementsPath,
  intlLocalePath,
  appointmentPath,
  applyPath,
  invitePath,
  configPath,
  handleConfigError,
  requestConfiguration,
  requestConfigurationUpdate,
  fetchIntlRequirementsConfig,
  fetchIntlRequirementsConfigFailure,
  forwardToRedirect,
  fetchConfiguration,
  fetchConfigFailure,
  fetchConfigSuccess,
  fetchSaveState,
  fetchSaveStateCancel,
  fetchSaveStateSuccess,
  fetchSaveStateFailure,
  requestAppointmentConfiguration,
  requestIntlRequirementConfig,
  fetchHealthScreeningConfigSuccess,
  setPartialSsn,
  isFound,
  getResourceLocale,
  transformStartEndDates,
  updateLocale,
  updatePartialSsn,
};
