import { omit } from 'lodash';

import * as ActionTypes from '../actions';

export const initialState = {};

const intlForm = (state = initialState, action) => {
  const {
    formValue,
    identityDocumentDefaultValues,
    key,
    type,
    candidateTaskErrors,
  } = action;

  switch (type) {
    case ActionTypes.CLEAR_FORM_VALUE:
      return {
        ...state,
        values: omit(state.values, [key]),
      };
    case ActionTypes.IDENTITY_DOCUMENT_DEFAULT_VALUES:
      return {
        ...state,
        values: {
          ...state.values,
          identity_document: identityDocumentDefaultValues,
        },
      };
    case ActionTypes.UPDATE_INTL_FORM_VALUE:
      return {
        ...state,
        values: {
          ...state.values,
          identity_document: {
            ...state.values.identity_document,
            [formValue.key]: formValue.value,
          },
        },
      };
    case ActionTypes.ADD_MEASURE_ONE_DATA:
      state.values.education.splice(-1); // splice to remove (duplicate) manual data add in redux store
      return {
        ...state,
        values: {
          ...state.values,
          education: [...state.values.education, formValue],
        },
      };
    case ActionTypes.STORE_BUFFER_MEASURE_ONE_DATA:
      return {
        ...state,
        bufferMeasureOneData: formValue,
      };
    case ActionTypes.CANDIDATE_TASK_FAILURE:
      return {
        ...state,
        candidateTaskErrors,
      };
    default:
      return state;
  }
};

export default intlForm;
