/* eslint-disable camelcase */
import { ENV } from '../../constants';
import de from './de.json';
import en from './en.json';
import en_XL from './en_XL.json';
import es from './es.json';
import es_MX from './es_MX.json';
import fr from './fr.json';
import fr_CA from './fr_CA.json';

export default function resources() {
  let loadResources = {
    en: {
      translations: en,
    },
    de: {
      translations: de,
    },
    es: {
      translations: es,
    },
    es_MX: {
      translations: es_MX,
    },
    fr: {
      translations: fr,
    },
    fr_CA: {
      translations: fr_CA,
    },
  };
  if (['development', 'staging', 'sandbox'].includes(ENV)) {
    loadResources = {
      ...loadResources,
      en_XL: {
        translations: en_XL,
      },
    };
  }
  return loadResources;
}
