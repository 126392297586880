import { get } from 'lodash';
import { i18n } from '@international/mastodon-i18n';

/**
 * @name isMobileClient
 * @function
 * @memberOf helpers
 * @description Mobile detection helper
 * @returns {boolean}
 */
const isMobileClient = {
  Android() {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry() {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera() {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows() {
    return navigator.userAgent.match(/IEMobile/i);
  },
  any() {
    return (
      isMobileClient.Android() ||
      isMobileClient.BlackBerry() ||
      isMobileClient.iOS() ||
      isMobileClient.Opera() ||
      isMobileClient.Windows()
    );
  },
};

/**
 * @name findEnvVar
 * @function
 * @memberOf helpers
 * @description Find an environment variable. When looking for environment variables,
 *              first look in the window object these will be defined by runtime env
 *              variables in production (not build args). Otherwise, look for the
 *              typical REACT_APP environment variables, via the standard mechanism.
 *              (inside of process.env)
 * @returns {String}
 */
const findEnvVar = varName => {
  const key = `REACT_APP_${varName}`;
  if (get(window, ['env', key]) !== undefined) {
    return window.env[key];
  }
  return process.env[key];
};

const formatPhoneNumber = phoneNumber => {
  const cleaned = `${phoneNumber}`.replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
};

const getLocalizedGlobalError = error => {
  let localizedError = i18n.getConstStr('errors', error);
  if (/(\d+)/.test(error)) {
    const matches = error.match(/(\d+)/);
    const value = matches && matches.length ? matches[0] : '';
    if (value) {
      localizedError = i18n.getConstStr(
        'errors',
        error.replace(value, ''),
        value,
      );
    }
  }
  return localizedError;
};

export {
  isMobileClient,
  formatPhoneNumber,
  findEnvVar,
  getLocalizedGlobalError,
};
