import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import { Translate as T } from 'react-redux-i18n';

import { requestApiStatus } from 'actions';

const StyledBanner = styled.div`
  background-color: ${colors.cautionYellow60};
  color: ${colors.coolBlack100};
  padding: 5px 0px;
  text-align: center;

  p {
    font-weight: bold;
    margin-bottom: 0px;
  }
`;

const ApiStatusBanner = ({ fetchApiStatus, incidents }) => {
  useEffect(() => {
    fetchApiStatus();
  }, [fetchApiStatus]);

  const applyIncidents = incidents.incidents
    ? incidents.incidents.filter(incident =>
        incident.components.some(component => component.name === 'Apply page'),
      )
    : [];

  const hasIncident = applyIncidents?.length;
  const hasMultipleIncidents = applyIncidents?.length > 1;

  return hasIncident ? (
    <StyledBanner>
      {hasIncident === 1 && (
        <p data-testid='single-incident'>
          <T
            value='components.ApiStatusBanner.singleIncident'
            incidentName={applyIncidents[0].name}
            dangerousHTML
          />
        </p>
      )}
      {hasMultipleIncidents && (
        <p data-testid='multiple-incidents'>
          <T
            value='components.ApiStatusBanner.multipleIncident'
            dangerousHTML
          />
        </p>
      )}
    </StyledBanner>
  ) : null;
};

ApiStatusBanner.propTypes = {
  incidents: PropTypes.object,
  fetchApiStatus: PropTypes.func,
};

ApiStatusBanner.defaultProps = {
  incidents: {},
  fetchApiStatus: () => {},
};

const mapStateToProps = state => {
  const { apiStatusBanner } = state;
  return {
    apiStatusRequest: apiStatusBanner.apiStatusRequest,
    incidents: apiStatusBanner.incidents,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchApiStatus: () => dispatch(requestApiStatus()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApiStatusBanner);
