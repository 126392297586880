const ADD_ARGYLE_CONNECTED_ACCOUNT_ID = 'ADD_ARGYLE_CONNECTED_ACCOUNT_ID';
const ADD_ARGYLE_ACCOUNT_EMPLOYMENTS = 'ADD_ARGYLE_ACCOUNT_EMPLOYMENTS';
const ARGYLE_LINK_CLOSED = 'ARGYLE_LINK_CLOSED';
const RESET_ARGYLE_DATA = 'RESET_ARGYLE_DATA';
const SET_ARGYLE_STATE = 'SET_ARGYLE_STATE';

export {
  ADD_ARGYLE_CONNECTED_ACCOUNT_ID,
  ADD_ARGYLE_ACCOUNT_EMPLOYMENTS,
  ARGYLE_LINK_CLOSED,
  RESET_ARGYLE_DATA,
  SET_ARGYLE_STATE,
};
