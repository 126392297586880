// Specific const for Canada region
// Should be refactored after "EPIC: Apply Flow + RCS schema refactor"(https://checkr.atlassian.net/browse/EINTXP-51)

// eslint-disable-next-line import/prefer-default-export
export type MvrProperty =
  | 'driver_license_country_of_issue'
  | 'driver_license_province_of_issue'
  | 'driver_license_number'
  | 'driver_license_class'
  | 'driver_license_expiration_date'
  | 'driver_license_image_upload'
  | 'driver_license_img_example_ca_ab'
  | 'driver_license_img_example_ca_bc'
  | 'driver_license_img_example_ca_mb'
  | 'driver_license_img_example_ca_nb'
  | 'driver_license_img_example_ca_nl'
  | 'driver_license_img_example_ca_ns'
  | 'driver_license_img_example_ca_nt'
  | 'driver_license_img_example_ca_nu'
  | 'driver_license_img_example_ca_on'
  | 'driver_license_img_example_ca_pe'
  | 'driver_license_img_example_ca_qc'
  | 'driver_license_img_example_ca_sk'
  | 'driver_license_img_example_ca_yt'
  | 'driver_license_mvr_with_history_ca_ab_authorization_form'
  | 'driver_license_mvr_with_history_ca_bc_authorization_form'
  | 'driver_license_mvr_with_history_ca_mb_authorization_form'
  | 'driver_license_mvr_with_history_ca_nb_authorization_form'
  | 'driver_license_mvr_with_history_ca_nl_authorization_form'
  | 'driver_license_mvr_with_history_ca_ns_authorization_form'
  | 'driver_license_mvr_with_history_ca_nt_authorization_form'
  | 'driver_license_mvr_with_history_ca_nu_authorization_form'
  | 'driver_license_mvr_with_history_ca_pe_authorization_form'
  | 'driver_license_mvr_with_history_ca_qc_authorization_form'
  | 'driver_license_mvr_with_history_ca_sk_authorization_form'
  | 'driver_license_mvr_with_history_ca_yt_authorization_form';

// eslint-disable-next-line import/prefer-default-export
export const MVR_PROPERTY_KEYS: Record<MvrProperty, MvrProperty> = {
  driver_license_country_of_issue: 'driver_license_country_of_issue',
  driver_license_province_of_issue: 'driver_license_province_of_issue',
  driver_license_number: 'driver_license_number',
  driver_license_class: 'driver_license_class',
  driver_license_expiration_date: 'driver_license_expiration_date',
  driver_license_image_upload: 'driver_license_image_upload',
  driver_license_img_example_ca_ab: 'driver_license_img_example_ca_ab',
  driver_license_img_example_ca_bc: 'driver_license_img_example_ca_bc',
  driver_license_img_example_ca_mb: 'driver_license_img_example_ca_mb',
  driver_license_img_example_ca_nb: 'driver_license_img_example_ca_nb',
  driver_license_img_example_ca_nl: 'driver_license_img_example_ca_nl',
  driver_license_img_example_ca_ns: 'driver_license_img_example_ca_ns',
  driver_license_img_example_ca_nt: 'driver_license_img_example_ca_nt',
  driver_license_img_example_ca_nu: 'driver_license_img_example_ca_nu',
  driver_license_img_example_ca_on: 'driver_license_img_example_ca_on',
  driver_license_img_example_ca_pe: 'driver_license_img_example_ca_pe',
  driver_license_img_example_ca_qc: 'driver_license_img_example_ca_qc',
  driver_license_img_example_ca_sk: 'driver_license_img_example_ca_sk',
  driver_license_img_example_ca_yt: 'driver_license_img_example_ca_yt',
  driver_license_mvr_with_history_ca_ab_authorization_form:
    'driver_license_mvr_with_history_ca_ab_authorization_form',
  driver_license_mvr_with_history_ca_bc_authorization_form:
    'driver_license_mvr_with_history_ca_bc_authorization_form',
  driver_license_mvr_with_history_ca_mb_authorization_form:
    'driver_license_mvr_with_history_ca_mb_authorization_form',
  driver_license_mvr_with_history_ca_nb_authorization_form:
    'driver_license_mvr_with_history_ca_nb_authorization_form',
  driver_license_mvr_with_history_ca_nl_authorization_form:
    'driver_license_mvr_with_history_ca_nl_authorization_form',
  driver_license_mvr_with_history_ca_ns_authorization_form:
    'driver_license_mvr_with_history_ca_ns_authorization_form',
  driver_license_mvr_with_history_ca_nt_authorization_form:
    'driver_license_mvr_with_history_ca_nt_authorization_form',
  driver_license_mvr_with_history_ca_nu_authorization_form:
    'driver_license_mvr_with_history_ca_nu_authorization_form',
  driver_license_mvr_with_history_ca_pe_authorization_form:
    'driver_license_mvr_with_history_ca_pe_authorization_form',
  driver_license_mvr_with_history_ca_qc_authorization_form:
    'driver_license_mvr_with_history_ca_qc_authorization_form',
  driver_license_mvr_with_history_ca_sk_authorization_form:
    'driver_license_mvr_with_history_ca_sk_authorization_form',
  driver_license_mvr_with_history_ca_yt_authorization_form:
    'driver_license_mvr_with_history_ca_yt_authorization_form',
};
