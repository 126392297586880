import { REQUIRED, VALID_CC } from './messages';

// eslint-disable-next-line consistent-return
const required = value => {
  if (!value) {
    return REQUIRED;
  }

  if (typeof value !== 'string') {
    // eslint-disable-next-line no-console
    console.debug('Attempted to require a ', typeof value);
    // eslint-disable-next-line consistent-return
    return;
  }

  if (value.trim() === '') {
    return REQUIRED;
  }
};

// eslint-disable-next-line consistent-return
const requiredArray = value => {
  if (!value || !value.length) {
    return REQUIRED;
  }
};

// eslint-disable-next-line consistent-return
const requiredCheckbox = value => {
  if (!value) {
    return REQUIRED;
  }
};

// eslint-disable-next-line consistent-return
const validCC = isValidCC => {
  if (!isValidCC) {
    return VALID_CC;
  }
};

export { required, requiredArray, requiredCheckbox, validCC };
