import { createContext } from 'react';

const EXAM_TYPES = {
  vaccine: 'vaccine',
  physical: 'physical',
};

const EXAM_SELECTIONS = {
  scheduleTest: 'scheduleTest',
  scheduleVaccine: 'scheduleVaccine',
  testForImmunity: 'testForImmunity',
  uploadDocumentation: 'uploadDocumentation',
};

const EXAM_CATEGORY_TYPES = {
  occupationalHealth: 'occupational_health',
  drug: 'drug',
};

const enabledCounterpartSlugs = exams => {
  const examSlugs = exams.map(e => e.name);

  return exams
    .map(e => e.counterpartSlug)
    .filter(Boolean)
    .filter(slug => examSlugs.includes(slug));
};

const filterWaivableHealthExams = exams => {
  const waivableExams = exams.filter(e => e.waivable);
  const counterpartSlugs = enabledCounterpartSlugs(waivableExams);

  return waivableExams
    .filter(({ name }) => !counterpartSlugs.includes(name))
    .map(exam => {
      if (counterpartSlugs.includes(exam.counterpartSlug)) {
        return exam;
      }
      return { ...exam, counterpartSlug: '' };
    });
};

const HealthScreeningContext = createContext({
  requireWaiverDocument: false,
  handleDocumentUpload: () => {},
  handleToggle: () => {},
});

export {
  EXAM_TYPES,
  EXAM_SELECTIONS,
  EXAM_CATEGORY_TYPES,
  filterWaivableHealthExams,
  HealthScreeningContext,
};
