import * as Sentry from '@sentry/react';
import checkrAnalytics, {
  getEventPropertiesFromStore,
  getUserPropertiesFromStore,
} from '../lib/analytics';

export const trackAnalyticsEvent = (eventTitle, eventProperties = {}) => (
  dispatch,
  getState,
) => {
  try {
    checkrAnalytics
      .track(
        {},
        eventTitle,
        {
          ...getEventPropertiesFromStore(eventTitle, getState()),
          ...eventProperties,
        },
        getUserPropertiesFromStore(getState()),
      )
      .catch(e => {
        Sentry.withScope(function(scope) {
          scope.setLevel('info');
          Sentry.captureException(e);
        });
      });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Analytics disabled due to cookies disabled.', e);
    Sentry.withScope(function(scope) {
      scope.setLevel('info');
      Sentry.captureException(e);
    });
  }
};

export default { trackAnalyticsEvent };
