import { REQUIRED_DOCUMENTS } from './messages';

// eslint-disable-next-line consistent-return
const requiredDocuments = minDocuments => documents => {
  if (!minDocuments) {
    // eslint-disable-next-line no-param-reassign
    minDocuments = 1;
  }

  if (!documents || documents.length < minDocuments) {
    return {
      message: `${REQUIRED_DOCUMENTS}.${minDocuments > 1 ? 'many' : 'one'}`,
      minDocuments,
    };
  }
};

const required2Documents = documents => {
  return !documents || documents.length < 2
    ? `${REQUIRED_DOCUMENTS}.two`
    : undefined;
};
const required1Document = requiredDocuments(1);

export { required1Document, required2Documents };
