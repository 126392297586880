import { moment, i18n } from '@international/mastodon-i18n';
import {
  DATE_AFTER,
  DATE_FORMAT,
  DATE_IN_THE_PAST,
  DATE_WITHIN_FIVE_YEARS,
  DATE_IN_THE_FUTURE,
} from './messages';

import { FULL_DATE_FORMAT } from '../../constants';

const defaultFormat = FULL_DATE_FORMAT;

const parseDate = (date, format) => {
  return moment(date, format, true);
};

const realisticDate = moment('1900', 'YYYY', true);

// eslint-disable-next-line consistent-return
const dateInThePast = (format = defaultFormat) => date => {
  if (!parseDate(date, format).isBefore(moment())) {
    return DATE_IN_THE_PAST;
  }
};

// eslint-disable-next-line consistent-return
const validDate = (format = defaultFormat) => date => {
  const parsedDate = parseDate(date, format);

  if (!parsedDate.isValid()) {
    return { message: DATE_FORMAT, format };
  }

  if (!parsedDate.isAfter(realisticDate)) {
    return {
      message: DATE_AFTER,
      dateCompared: i18n.getLocalizedDate(realisticDate),
    };
  }
};

// eslint-disable-next-line consistent-return
const dateAfter = (format = defaultFormat, dateCompared) => date => {
  const before = parseDate(dateCompared, format);
  const after = parseDate(date, format);

  if (before.isValid() && after.isBefore(before)) {
    return { message: DATE_AFTER, dateCompared };
  }
};

// eslint-disable-next-line consistent-return
const dateValidIfAvailable = (format = defaultFormat) => date => {
  if (date) {
    return validDate(format)(date) || dateInThePast(format)(date);
  }
};

// eslint-disable-next-line consistent-return
const dateWithinFiveYears = (format = defaultFormat) => date => {
  const fiveYearsAgo = moment()
    .subtract(5, 'years')
    .startOf('month');
  const addressDate = parseDate(date, format);

  if (addressDate.isBefore(fiveYearsAgo)) {
    return DATE_WITHIN_FIVE_YEARS;
  }
};

const validExpirationDate = (model, isYear = false) => {
  const { dlExpirationDate } = model;

  if (dlExpirationDate.year && dlExpirationDate.month && dlExpirationDate.day) {
    const date = `${dlExpirationDate.month}/${dlExpirationDate.day}/${dlExpirationDate.year}`;
    const parsedDate = moment(new Date(Date.parse(date)));

    if (parsedDate.isBefore(moment())) {
      if (isYear) {
        return DATE_IN_THE_FUTURE;
      }
      return ' ';
    }
  }

  return undefined;
};

export {
  dateAfter,
  dateInThePast,
  dateValidIfAvailable,
  validDate,
  dateWithinFiveYears,
  validExpirationDate,
};
