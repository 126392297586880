const OPEN_MODAL = 'OPEN_MODAL';
const CLOSE_MODAL = 'CLOSE_MODAL';

const openModal = (childComponent, childComponentProps) => dispatch => {
  dispatch(openModalAction(childComponent, childComponentProps));
};

const openModalAction = (childComponent, childComponentProps) => ({
  type: OPEN_MODAL,
  childComponent,
  childComponentProps,
});

const closeModal = () => dispatch => {
  dispatch(closeModalAction());
};

const closeModalAction = () => ({ type: CLOSE_MODAL });

export {
  OPEN_MODAL,
  CLOSE_MODAL,
  openModal,
  closeModal,
  openModalAction,
  closeModalAction,
};
