import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { i18nReducer as i18n } from 'react-redux-i18n';

import apiStatusBanner from './apiStatusBanner';
import applyform from './applyform';
import configuration from './configuration';
import creditCard from './creditCard';
import employmentScreening from './employment/employmentScreeningReducer';
import healthScreening from './healthScreening';
import internationalAddressHistory from './internationalAddressHistory';
import intlForm from './intlForm';
import modal from './modal';
import navigation from './navigation';

const rootReducer = combineReducers({
  apiStatusBanner,
  configuration,
  creditCard,
  employmentScreening,
  form: form.plugin({ applyform, intlForm }),
  healthScreening,
  i18n,
  internationalAddressHistory,
  modal,
  navigation,
});

export default rootReducer;
