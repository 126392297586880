// eslint-disable-next-line import/prefer-default-export
export const UI_SPECIFIC_INFO = 'uiSpecificInformation';
export const ONE_HOUR = 1000 * 60 * 55;
export const DATE_PLACEHOLDER = [
  'MM/YYYY',
  'MM-YYYY',
  'MM.YYYY',
  'MM/DD/YYYY',
  'MM-DD-YYYY',
  'MM.DD.YYYY',
  'DD/MM/YYYY',
  'DD-MM-YYYY',
  'DD.MM.YYYY',
  'YYYY',
  'YYYY/MM',
  'YYYY-MM',
  'YYYY.MM',
  'YYYY/MM/DD',
  'YYYY-MM-DD',
  'YYYY.MM.DD',
  'YYYY/DD/MM',
  'YYYY-DD-MM',
  'YYYY.DD.MM',
  'Year Awarded',
];

// Specific const for Canada region
// Should be refactored after "EPIC: Apply Flow + RCS schema refactor"(https://checkr.atlassian.net/browse/EINTXP-51)

// eslint-disable-next-line import/prefer-default-export
export type RtwProperty =
  | 'citizenship_status'
  | 'has_valid_passport'
  | 'share_code'
  | 'no_share_code';

// eslint-disable-next-line import/prefer-default-export
export const RTW_PROPERTY_KEYS: Record<RtwProperty, RtwProperty> = {
  citizenship_status: 'citizenship_status',
  has_valid_passport: 'has_valid_passport',
  share_code: 'share_code',
  no_share_code: 'no_share_code',
};

export const RTW_PASSPORT_STATUS = { NATIONAL: 'NATIONAL', FOREIGN: 'FOREIGN' };
