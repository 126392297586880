import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Loadable from 'react-loadable';

import Loading from '../components/Loading';

const App = Loadable({
  loader: () => import(/* webpackChunkName: "app" */ '../containers/App'),
  loading: Loading,
});

const AppointmentApp = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "appointment-app" */ '../containers/AppointmentApp'
    ),
  loading: Loading,
});

const IdVerification = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "id-verification" */ '../containers/IdVerification'
    ),
  loading: Loading,
});

const InternalApps = Loadable({
  loader: () =>
    import(/* webpackChunkName: "internal" */ '../containers/InternalApps'),
  loading: Loading,
});

const CommonRoutes = () => {
  return (
    <Switch>
      <Route key='0' path='/invite/:company/:token/:test?' component={App} />
      <Route key='1' path='/apply/:company/:hash/:test?' component={App} />
      <Route
        key='2'
        path='/appointments/:company/:appointmentId/:test?'
        component={AppointmentApp}
      />
      <Route
        key='3'
        path='/id_verifications/:resourceId'
        component={IdVerification}
      />
      <Route key='4' path='/internal/:app' component={InternalApps} />
    </Switch>
  );
};

export default CommonRoutes;
